import React, { useEffect, useRef, useState } from "react";
import "./spottrading.scss";
import { Loader } from "../../../components/usercomponent/Loader";
import { ActivePair } from "../../../components/usercomponent/tradecomponent/ActivePair";
import { useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import Cookie from "js-cookie";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { MasterTrade } from "../../../components/usercomponent/buysellcomponent/MasterTrade";
export const SpotTrading = () => {
  let { pair } = useParams("pair");
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const token = Cookie.get("srxtoken");
  const uuid = Cookie.get("srxuuid");
  const [loader, setLoader] = useState(true);
  const [market, setMarket] = useState({});
  const [base, setBase] = useState({});
  const [quote, setQuote] = useState({});
  const [buyprice, setBuyPrice] = useState(0);
  const [buyQnty, setBuyQnty] = useState(0);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellprice, setSellPrice] = useState(0);
  const [sellQnty, setSellQnty] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [ticker, setTicker] = useState({});
  const [btnbuy, setBtnBuy] = useState(0);
  const [btnsell, setBtnSell] = useState(0);
  const [bids, setBids] = useState({});
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [bidOrder, setBidOrders] = useState();
  const [asks, setAsks] = useState();
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(
          url + "/user/gettradedata",
          { pair, uuid },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        )
        .then(function (resp) {
          if (resp.status === 200) {
            setBase(resp.data.base);
            setQuote(resp.data.quote);
            setTicker(resp.data.ticker);
            setBuyPrice(resp.data.ticker.high);
            setSellPrice(resp.data.ticker.low);
            setBids(resp.data.Bids);
            setAsks(resp.data.Asks);
            setLoader(false);
          }
        });

      const market = await axios.get(url + "/availableintrade", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (market.status === 200) {
        setMarket(market.data.market);
      }
    };
    fetchData();
  }, []);
  const marketpair = (marketData) => {
    return (
      <div>
        <div className="text-primary text-base font-bold">
          {marketData.base_asset}/
        </div>
        <div className="text-300 text-xs">{marketData.quote_asset}</div>
      </div>
    );
  };
  const marketAction = (marketData) => {
    return (
      <div>
        {marketData.status === "Trading" ? (
          <Button label="Trade" size="small" />
        ) : (
          <Button disabled label="Trade" size="small" />
        )}
      </div>
    );
  };
  const marketVol = (marketData) => {
    return <div>0.00</div>;
  };
  const onChange = (side, chageval, val) => {
    if (side === "Bid") {
      if (chageval === "price") {
        setBuyPrice(val);
        setBuyAmount(parseFloat(val * buyQnty).toFixed(4));
      } else if (chageval === "base") {
        setBuyQnty(val);
        setBuyAmount(parseFloat(val * buyprice).toFixed(4));
      } else if (chageval === "quote") {
        setBuyAmount(val);
        setBuyQnty(parseFloat(val/buyprice).toFixed(4));
      }
    } else if (side === "Ask") {
      if (chageval === "price") {
        setSellPrice(val);
        setSellAmount(parseFloat(val * sellQnty).toFixed(3));
      } else if (chageval === "base") {
        setSellQnty(val);
        setSellAmount((val * sellprice));
      } else if (chageval === "quote") {
        setSellAmount(val);
        setSellQnty(parseFloat(val/sellprice).toFixed(4));
      }
    }
  };
  const setPer = async (side, per) => {
    if (side === "Bid") {
      const fetchBalance = await axios.post(
        url + `/user/getbalance/${quote.currency}/Spot`,
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
            "act-sessionid": token,
          },
        }
      );
      if (fetchBalance.status === 200) {
        let balance = fetchBalance.data.inuse;
        balance = parseFloat(balance).toFixed(4)
        setBuyAmount((balance * per) / 100);
        setBuyQnty(parseFloat(((balance * per) / 100) / buyprice).toFixed(4));
      }
    } else if (side === "Ask") {
      const fetchBalance = await axios.post(
        url + `/user/getbalance/${base.currency}/Spot`,
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
            "act-sessionid": token,
          },
        }
      );
      if (fetchBalance.status === 200) {
        let balance = fetchBalance.data.inuse;
        setSellQnty((balance * per) / 100);
        setSellAmount(parseFloat(((balance * per) / 100) * sellprice).toFixed(4));
      }
    }
  };
  const makeBid = (side) => {
    if (side === "Bid") {
      if (
        buyAmount === 0 ||
        buyQnty === 0 ||
        buyprice === 0 ||
        quote.inuse * 1 < buyAmount
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong!!!",
        });
        return false;
      }
      // setBtnBuy(1);
      axios
        .post(
          url + "/user/createspotorder",
          {
            uuid,
            pair,
            side,
            price: buyprice,
            order_type: "Spot",
            amount: buyAmount,
          },
          {
            headers: {
              "x-api-key": apikey,
              "act-sessionid": token,
            },
          }
        )
        .then(async function (response) {
          // setBtnBuy(2);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Placed Successfully.",
          });
          await axios
            .post(
              url + "/user/gettradedata",
              { pair, uuid },
              {
                headers: {
                  "x-api-key": apikey,
                },
              }
            )
            .then(function (resp) {
              if (resp.status === 200) {
                setBase(resp.data.base);
                setQuote(resp.data.quote);
                setTicker(resp.data.ticker);
                setBuyPrice(resp.data.ticker.high);
                setSellPrice(resp.data.ticker.low);
                setBids(resp.data.Bids);
                setAsks(resp.data.Asks)
              }
            });
        })
        .catch(function (error) {
          // setBtnBuy(0);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong!!!",
          });
        });
    }else if(side==='Ask'){
      if (
        sellAmount === 0 ||
        sellQnty === 0 ||
        sellprice === 0 ||
        base.inuse * 1 < sellAmount
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong!!!",
        });
        return false;
      }
      //setBtnSell(1);
      axios
        .post(
          url + "/user/createspotorder",
          {
            uuid,
            pair,
            side,
            price: sellprice,
            order_type: "Spot",
            amount: sellQnty,
          },
          {
            headers: {
              "x-api-key": apikey,
              "act-sessionid": token,
            },
          }
        )
        .then(async function (response) {
          //setBtnSell(2);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Placed Successfully.",
          });
          await axios
            .post(
              url + "/user/gettradedata",
              { pair, uuid },
              {
                headers: {
                  "x-api-key": apikey,
                },
              }
            )
            .then(function (resp) {
              if (resp.status === 200) {
                setBase(resp.data.base);
                setQuote(resp.data.quote);
                setTicker(resp.data.ticker);
                setBuyPrice(resp.data.ticker.high);
                setSellPrice(resp.data.ticker.low);
                setBids(resp.data.Bids);
                setAsks(resp.data.Asks)
              }
            });
        })
        .catch(function (error) {
          //setBtnSell(0);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong!!!",
          });
        });
    }
  };
  const confDelBidOrder = (order) => {
    setBidOrders(order.orderid);
    setDeleteProductDialog(true);
  };
  const bidOrders = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => confDelBidOrder(rowData)}
        />
      </>
    );
  };
  const qnty = (rowData) => {
    return <>{rowData.amount / 10e7}</>;
  };
  const price = (rowData) => {
    return <>{rowData.price / 10e7}</>;
  };
  const bidTrade = (
    <div className="flex flex-column gap-2" style={{width:'100%'}}>
    <div className="text-primary border-2 border-dashed surface-border border-round surface-ground p-2">Bids</div>
    <DataTable value={bids} size="small" style={{width:'100%'}}>
      <Column field="tran_date" header="Date"></Column>
      <Column field={price} header="Price"></Column>
      <Column body={qnty} header="Amount"></Column>
      <Column header="Action" body={bidOrders}></Column>
    </DataTable>
    </div>
  );
  const askTrade = (
    <div className="flex flex-column gap-2" style={{width:'100%'}}>
    <div className="text-primary border-2 border-dashed surface-border border-round surface-ground p-2">Asks</div>
    <DataTable value={asks} size="small" style={{width:'100%'}}>
      <Column field="tran_date" header="Date"></Column>
      <Column field={price} header="Price"></Column>
      <Column body={qnty} header="Qnty"></Column>
      <Column header="Action" body={bidOrders}></Column>
    </DataTable>
    </div>
  );

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const DeleteOrder = async () => {
    setDeleteProductDialog(false);
    const resp = await axios.post(
      url + "/user/deletOrder",
      { uuid, bidOrder },
      {
        headers: {
          "x-api-key": apikey,
          "act-sessionid": token,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      await axios
        .post(
          url + "/user/gettradedata",
          { pair, uuid },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        )
        .then(function (resp) {
          if (resp.status === 200) {
            setBase(resp.data.base);
            setQuote(resp.data.quote);
            setTicker(resp.data.ticker);
            setBuyPrice(resp.data.ticker.low);
            setSellPrice(resp.data.ticker.high);
            setBids(resp.data.Bids);
            console.log(resp.data.base);
          }
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => DeleteOrder()}
      />
    </React.Fragment>
  );
  return (
    <div className="spottrading">
      <Toast ref={toast} />
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="content">
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-6">
                <ActivePair pair={pair} />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                {/* <TradeBalance pair={pair} /> */}
              </div>
            </div>
            <div className="tradinstage">
              <div className="grid">
                <div className="avlpair col-12 md:col-12 lg:col-3 first">
                  <DataTable
                    value={market}
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Column field={marketpair} header="Coin/Token"></Column>
                    <Column field={marketVol} header="Vol"></Column>
                    <Column field={marketAction} header="Action"></Column>
                  </DataTable>
                </div>
                <div className="buysell col-12 md:col-12 lg:col-6">
                  <TabView>
                    <TabPanel
                      header="Buy"
                      className="flex flex-column gap-2"
                      style={{ height: "100%" }}
                    >
                      <div className="balance flex align-items-center justify-content-between p-2 border-2 border-dashed surface-border border-round surface-ground">
                        <div className="basebal flex gap-2">
                          <div>{base.currency}</div>
                          <>{(base.inuse).toFixed(3)}</>
                        </div>
                        <div className="quotebal flex gap-2">
                          <div>{quote.currency}</div>
                          <>{(quote.inuse).toFixed(3)}</>
                        </div>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder="Price"
                          value={buyprice}
                          onChange={(e) =>
                            onChange("Bid", "price", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">Price</span>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder={base.currency}
                          value={buyQnty}
                          onChange={(e) =>
                            onChange("Bid", "base", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">
                          {base.currency}
                        </span>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder={quote.currency}
                          value={buyAmount}
                          onChange={(e) =>
                            onChange("Bid", "quote", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">
                          {quote.currency}
                        </span>
                      </div>
                      <div className="flex border-2 border-dashed surface-border border-round surface-ground align-items-center justify-content-between p-1">
                        <Button
                          size="small"
                          label="25%"
                          onClick={() => setPer("Bid", 25)}
                          style={{ width: "20%" }}
                        />
                        <Button
                          size="small"
                          label="50%"
                          onClick={() => setPer("Bid", 50)}
                          style={{ width: "20%" }}
                        />
                        <Button
                          size="small"
                          label="75%"
                          onClick={() => setPer("Bid", 75)}
                          style={{ width: "20%" }}
                        />
                        <Button
                          size="small"
                          label="100%"
                          onClick={() => setPer("Bid", 100)}
                          style={{ width: "20%" }}
                        />
                      </div>
                      <div className="btn">
                        <div className="flex flex-1">
                          {btnbuy === 0 ? (
                            <Button
                              label="Buy"
                              onClick={() => makeBid("Bid")}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <>
                              {btnbuy === 1 ? (
                                <Button
                                  disabled
                                  label="Processing"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                <Button
                                  label="Success"
                                  style={{ width: "100%" }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {/* <FrameBuy
                        pair={pair}
                        uuid={uuid}
                        style={{ height: "100%" }}
                      /> */}
                    </TabPanel>
                    <TabPanel
                      header="Sell"
                      className="flex flex-column gap-2"
                      style={{ height: "100%" }}
                    >
                      <div className="balance flex align-items-center justify-content-between p-2 border-2 border-dashed surface-border border-round surface-ground">
                        <div className="basebal flex gap-2">
                          <div>{base.currency}</div>
                          <>{(base.inuse)}</>
                        </div>
                        <div className="quotebal flex gap-2">
                          <div>{quote.currency}</div>
                          <>{(quote.inuse)}</>
                        </div>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder="Price"
                          readOnly
                          value={sellprice}
                          onChange={(e) =>
                            onChange("Ask", "price", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">Price</span>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder={base.currency}
                          value={sellQnty}
                          onChange={(e) =>
                            onChange("Ask", "base", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">
                          {base.currency}
                        </span>
                      </div>
                      <div className="p-inputgroup">
                        <InputText
                          placeholder={quote.currency}
                          value={sellAmount}
                          onChange={(e) =>
                            onChange("Ask", "quote", e.target.value)
                          }
                        />
                        <span className="p-inputgroup-addon">
                          {quote.currency}
                        </span>
                      </div>
                      <div className="flex border-2 border-dashed surface-border border-round surface-ground align-items-center justify-content-between p-1">
                        <Button
                          severity="danger"
                          size="small"
                          onClick={() => setPer("Ask", 25)}
                          label="25%"
                          style={{ width: "20%" }}
                        />
                        <Button
                          severity="danger"
                          size="small"
                          onClick={() => setPer("Ask", 50)}
                          label="50%"
                          style={{ width: "20%" }}
                        />
                        <Button
                          severity="danger"
                          size="small"
                          onClick={() => setPer("Ask", 75)}
                          label="75%"
                          style={{ width: "20%" }}
                        />
                        <Button
                          severity="danger"
                          size="small"
                          onClick={() => setPer("Ask", 100)}
                          label="100%"
                          style={{ width: "20%" }}
                        />
                      </div>
                      <div className="btn">
                        <div className="flex flex-1">
                          {btnsell === 0 ? (
                            <Button
                              label="Sell"
                              severity="danger"
                              onClick={() => makeBid('Ask')}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <>
                              {btnsell === 1 ? (
                                <Button
                                severity="danger"
                                  label="Processing"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                <Button
                                  label="Success"
                                  severity="danger"
                                  style={{ width: "100%" }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {/* <FrameSell
                        pair={pair}
                        uuid={uuid}
                        style={{ height: "100%" }}
                      /> */}
                    </TabPanel>
                    <TabPanel header="Trade" className="flex flex-column gap-2">
                      <MasterTrade />
                    </TabPanel>
                  </TabView>
                </div>
                <div className="col-12 md:col-12 lg:col-3"></div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-6">
                <Message severity="success" content={bidTrade} style={{width:'100%', padding:1}} />
              </div>
              <div className="col-12 md:col-12 lg:col-6">
                <Message severity="error" content={askTrade} style={{width:'100%', padding:1}} />
              </div>
            </div>
          </div>
          <Dialog
            visible={deleteProductDialog}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {bidOrder && (
                <span>
                  Are you sure you want to delete <b>{bidOrder.orderid}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};
