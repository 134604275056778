import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment"
import { Message } from "primereact/message";
export const AdminInrWithdraw = () => {
    const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [deposits, setDeposits] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getinrwithdraw", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setDeposits(resp.data.inrdeposit);
      }
    };
    fetchData();
  }, []);
  const acceptWithdraw = async (tranid) => {
    alert(tranid);
    const resp = await axios.post(
      url + "/admin/acceptinrdeposit",
      {
        tranid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
  };
  const tranAccept = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-check-square"
          severity="info"
          size="small"
          onClick={() => acceptWithdraw(rowData.tranid)}
        />
      </>
    );
  };
  const tranReject = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          severity="warning"
          size="small"
        //   onClick={() => rejectWithdraw(rowData.tranid)}
        />
      </>
    );
  };
  const trandate = (rowData) => {
    return (
      <>
        {moment(rowData.tran_date).format("YYYY-MM-DD")}
      </>
    );
  };
  const amount = (rowData) => {
    return (
      <>
        {(rowData.amount*1).toFixed(2)}
      </>
    );
  };
  const tds = (rowData) => {
    return (
      <>
        {((rowData.amount*1).toFixed(2)*1/100).toFixed(2)}
      </>
    );
  };
  const finalamount = (rowData) => {
    return (
      <div className="text-primary text-2xl text-bold">
        {parseInt(rowData.amount - ((rowData.amount*1).toFixed(2)*1/100).toFixed(2))}
      </div>
    );
  };
  return (
    <div style={{padding:"80px 20px"}}>
        <Message text="INRWITHDRAW" style={{width:'100%', marginBottom:5, justifyContent:"left"}} />
        <DataTable value={deposits} size="small" style={{ width: "100%", }}>
        <Column field={trandate} header="Date"></Column>
        <Column field="user_name" header="User Name"></Column>
        <Column field="mobile" header="Mobile"></Column>
        <Column field="accno" header="Acc. No"></Column>
        <Column field="bank" header="Bank"></Column>
        <Column field="branch" header="Branch"></Column>
        <Column field="ifsc" header="IFSC"></Column>
        <Column field={amount} header="Base Amount"></Column>
        <Column field={tds} header="Tds Amount"></Column>
        <Column field={finalamount} header="Final Amount"></Column>
        <Column header="Action" body={tranAccept}></Column>
        <Column header="Action" body={tranReject}></Column>
      </DataTable>
    </div>
  )
}
