import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import Cookie from "js-cookie";
import "./signup.scss";
export const SignUp = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otpStatus, setOtpStatus] = useState(0);
  const [checked, setChecked] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [btnEmailOTP, setBtnEmailOTP] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const hostPath = process.env.REACT_APP_URL;
  const [visible, setVisible] = useState(false);
  const validationSchema = Yup.object({
    otp:Yup.number().required("Field can not be empty"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password not matched"
    ),
    refcode: Yup.mixed(),
  });
  const formik = useFormik({
    initialValues: {
      otp:"",
      password: "",
      cpassword: "",
      refcode: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/signupconf",
        {
          email,
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        setTimeout(function () {
          Navigate("/login");
        }, 5000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Credential! Try Correcting again.",
        });
      }
    },
  });
  const InitSignUp = async () => {
    setOtpStatus(1);
    const resp = await axios.post(
      url + "/signupinit",
      {
        email,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setOtpStatus(2);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Email OTP send Successfully",
      });
    } else {
      setOtpStatus(0);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="signup">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/logo.png" width="100" alt="" />
                    <h3>USER SIGNUP</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        className="p-inputtext-sm"
                        placeholder="Email Address"
                        autoFocus
                        disabled={otpStatus === 0 ? false : true}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <Button
                        type="button"
                        label="Init Signup"
                        disabled={otpStatus === 0 ? false : true}
                        onClick={() => InitSignUp()}
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="otp"
                        placeholder="Mail OTP"
                        disabled={otpStatus===0?true:false}
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                      />
                      {getFormErrorMessage("otp")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="p-inputtext-sm"
                        disabled={otpStatus===0?true:false}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        toggleMask
                      />
                      {getFormErrorMessage("password")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="cpassword"
                        placeholder="Confirm Password"
                        className="p-inputtext-sm"
                        disabled={otpStatus===0?true:false}
                        onChange={formik.handleChange}
                        value={formik.values.cpassword}
                        toggleMask
                      />
                      {getFormErrorMessage("cpassword")}
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="refcode"
                        placeholder="Ref. Code"
                        className="p-inputtext-sm"
                        disabled={otpStatus===0?true:false}
                        onChange={formik.handleChange}
                        value={formik.values.refcode}
                      />
                      {getFormErrorMessage("refcode")}
                    </div>
                    <div className="component">
                      <Button type="submit" label="Submit" size="large" disabled={otpStatus===0?true:false} />
                    </div>

                    <Divider />
                    <div className="component">
                      <Link to="/login">Already Have Account?</Link>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
