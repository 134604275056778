// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .hero {
  padding: 120px 80px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
}
.dashboard .hero .headdata .wel {
  font-size: 58px;
  font-weight: 700;
  color: #08e279;
  text-shadow: 2px 2px #77858e;
}
.dashboard .hero .headdata .user {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.dashboard .hero .headdata .title {
  font-size: 58px;
  font-weight: 700;
  color: #e28b08;
  text-shadow: 2px 2px #414a51;
}
.dashboard .hero .headdata .headone,
.dashboard .hero .headdata .headtwo,
.dashboard .hero .headdata .headthree {
  font-size: 40px;
  font-weight: 700;
}
.dashboard .hero .headdata .headtwo {
  font-size: 56px;
}
.dashboard .hero .right {
  padding: 100px;
}
@media (max-width: 900.1px) {
  .dashboard .hero {
    padding: 80px 15px;
    background-size: auto;
  }
  .dashboard .hero .headdata .headtwo {
    font-size: 46px;
  }
  .dashboard .hero .right {
    padding: 10px;
  }
  .dashboard .hero .right .refmessage {
    font-size: 12px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/user/dashboard/dashboard.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;EACA,mDAAA;EACA,0BAAA;AADJ;AAGM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,4BAAA;AADR;AAGM;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AADR;AAGM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,4BAAA;AADR;AAGM;;;EAGE,eAAA;EACA,gBAAA;AADR;AAGM;EACE,eAAA;AADR;AAII;EACE,cAAA;AAFN;AAKI;EApCF;IAqCI,kBAAA;IACA,qBAAA;EAFJ;EAIM;IACE,eAAA;EAFR;EAMI;IACE,aAAA;EAJN;EAKM;IACE,0BAAA;EAHR;AACF","sourcesContent":["@import \"../../../style.scss\";\n.dashboard {\n  .hero {\n    padding: 120px 80px;\n    background: url(\"../../../../public/images/frontcover.png\");\n    background-size: 100% 100%;\n    .headdata {\n      .wel {\n        font-size: 58px;\n        font-weight: 700;\n        color: #08e279;\n        text-shadow: 2px 2px #77858e;\n      }\n      .user {\n        font-weight: 700;\n        font-size: 18px;\n        color: #fff;\n      }\n      .title {\n        font-size: 58px;\n        font-weight: 700;\n        color: #e28b08;\n        text-shadow: 2px 2px #414a51;\n      }\n      .headone,\n      .headtwo,\n      .headthree {\n        font-size: 40px;\n        font-weight: 700;\n      }\n      .headtwo {\n        font-size: 56px;\n      }\n    }\n    .right{\n      padding: 100px;\n    }\n    $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      padding: 80px 15px;\n      background-size: auto;\n      .headdata {\n        .headtwo {\n          font-size: 46px;\n        }\n      }\n      \n      .right{\n        padding: 10px;\n        .refmessage{\n          font-size: 12px !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
