import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import Cookie from "js-cookie";
import "./login.scss";
export const ForgetPassword = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [btnEmailOTP, setBtnEmailOTP] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    authcode: Yup.number("Invalid Recovery Code.").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
    cpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password not matched"
    ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      cpassword: "",
      authcode: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //   setButtonDisabled(true);
      const resp = await axios.post(
        url + "/confforgetpass",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        setTimeout(function () {
          Navigate("/login");
        }, 5000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const sendEmailOTP = async () => {
    const resp = await axios.post(
      url + "/forgetpass",
      {
        email: formik.values.email,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setBtnEmailOTP(1);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="login">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="rounded-0">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/logo.png" width="100" alt="" />
                    <h3>Password Recovery</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        autoFocus
                        disabled={btnEmailOTP === 0 ? false : true}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <Button
                        type="button"
                        icon="pi pi-send"
                        label="Request Recovery Code"
                        disabled={btnEmailOTP === 0 ? false : true}
                        onClick={() => sendEmailOTP()}
                        size="small"
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="authcode"
                        className="p-inputtext-sm"
                        placeholder="Recovery Code"
                        disabled={btnEmailOTP === 0 ? true : false}
                        onChange={formik.handleChange}
                        value={formik.values.authcode}
                      />
                      {getFormErrorMessage("authcode")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="password"
                        placeholder="Password"
                        disabled={btnEmailOTP === 0 ? true : false}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        toggleMask
                      />
                      {getFormErrorMessage("password")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="cpassword"
                        placeholder="Password"
                        disabled={btnEmailOTP === 0 ? true : false}
                        onChange={formik.handleChange}
                        value={formik.values.cpassword}
                        toggleMask
                      />
                      {getFormErrorMessage("cpassword")}
                    </div>
                    <div className="component">
                      {btnEmailOTP === 0 ? (
                        <Button
                          type="button"
                          disabled
                          label="Submit"
                          size="large"
                        />
                      ) : (
                        <>
                          {isButtonDisabled === 0 ? (
                            <Button type="submit" label="Submit" size="small" />
                          ) : (
                            <>
                              {isButtonDisabled === 1 ? (
                                <Button
                                  type="button"
                                  disabled
                                  label="Proceesing"
                                  size="small"
                                />
                              ) : (
                                <Button
                                  type="button"
                                  disabled
                                  label="Submitted"
                                  size="small"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="component">
                      <p>
                        Navigate to? <Link to="/signup">Sign Up</Link> /{" "}
                        <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
