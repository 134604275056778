import React from 'react'
import "./loader.scss"
export const Loader = () => {
  return (
    <div className='loader'>
        <div className="animation">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
        </div>
    </div>
  )
}
