import React, { useEffect, useRef, useState } from "react";
import "./security.scss";
import { Message } from "primereact/message";
import axios from "axios";
import Cookie from "js-cookie";
import qrCode from "qrcode";
import { Form, FormikProvider, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
export const Security = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const token = Cookie.get("srxtoken");
  const [user, setUser] = useState({});
  const [fa, setFa] = useState(false)
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("");
  const [authcode, setAuthCode] = useState("");
  const [qrImage, setQrImage] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdashboarddata",
        { uuid },
        {
          headers: {
            "x-api-key": apikey,
            "act-sessionid": token,
          },
        }
      );
      if (resp.status === 200) {
        setUser(resp.data.userdata);
        setFa(resp.data.userdata.mfa_enabled)
      }
    };
    fetchData();
  }, []);
  const getTOTP = async () => {
    const resp = await axios.post(
      url + "/user/gentotp",
      { uuid },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setKey(resp.data.key);
      setQrImage(resp.data.imagePath);
      setVisible(true);
    }
  };
  const auth2fa = (
    <div className="flex flex-column align-items-center gap-3 w-100">
      <div className="title">2FA Authentication</div>
      {fa===false?
      <>
      <div className="msg">Not Set</div>
      <Button label="Set It" size="small" onClick={() => getTOTP()} />
      </>
      :
      <Message severity="success" text={'Is Active'} style={{width:'100%'}} />
      }
      
    </div>
  );
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Content copied successfully.",
    });
  };
  const setTOTP = async () => {
    const resp = await axios.post(
      url + "/user/settotp",
      { uuid, key, authcode },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      setFa(true)
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  return (
    <div className="security">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-6 md:col-3 lg:col-3">
            <Message style={{ width: "100%" }} content={auth2fa} />
          </div>
          {/* <div className="col-6 md:col-3 lg:col-3">
            <Message style={{ width: "100%" }} content={antipishing} />
          </div>
          <div className="col-6 md:col-3 lg:col-3">
            <Message style={{ width: "100%" }} content={antipishing} />
          </div>
          <div className="col-6 md:col-3 lg:col-3">
            <Message style={{ width: "100%" }} content={antipishing} />
          </div> */}
        </div>
      </div>
      <Dialog
        header="2FA Setup"
        style={{ width: "350px" }}
        visible={visible}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="flex flex-column gap-2 align-items-center">
          <img src={qrImage} alt="" width={150} height={150} />
          <div style={{ width: "200px", overflow: "hidden", fontSize: "12px" }}>
            {key}
          </div>
          <Button
            label="Copy Code"
            size="small"
            style={{ width: "100%" }}
            severity="warning"
            onClick={() => copyText({ key })}
          />
          <InputText
            className="p-inputtext-sm"
            value={authcode}
            onChange={(e) => setAuthCode(e.target.value)}
          />
          <Button
            label="Confirm"
            size="small"
            style={{ width: "100%" }}
            onClick={() => setTOTP()}
          />
        </div>
      </Dialog>
    </div>
  );
};
