import React, { useEffect, useState } from "react";
import "./profile.scss";
import axios from "axios";
import Cookie from "js-cookie";
import { Message } from "primereact/message";
export const Profile = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const token = Cookie.get("srxtoken");
  const [user, setUser] = useState({});
  const [bank, setBank] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(
          url + "/user/getprofiledata",
          {
            uuid,
          },
          {
            headers: {
              "x-api-key": apikey,
              "act-sessionid": token,
            },
          }
        )
        .then(function (resp) {
          if (resp.status === 200) {
            setUser(resp.data.userdata);
            setBank(resp.data.bank);
            //setData(resp.data.userdata);
            //setLoader(false);
          }
        })
        .catch(function (error) {
          //Navigate("/");
        });
    };
    fetchData();
  }, []);
  const basicprofile = (
    <div className="flex flex-column gap-3" style={{width:'100%'}}>
      <div>Basic Profile</div>  
      <div className="cont">
        <div>Name</div>
        <div>{user.first_name}</div>
      </div>
      <div className="cont">
        <div>Email</div>
        <div>{user.email}</div>
      </div>
      <div className="cont">
        <div>Mobile No</div>
        <div>{user.phone_number}</div>
      </div>
      <div className="cont">
        <div>Platform Id</div>
        <div>{user.platform_id}</div>
      </div>
    </div>
  );
  const userbank = (
    <div className="flex flex-column gap-3" style={{width:'100%'}}>
      <div>Bank Details</div>  
      <div className="cont">
        <div>Bank Name</div>
        <div>{bank.bank}</div>
      </div>
      <div className="cont">
        <div>Account No.</div>
        <div>{bank.accno}</div>
      </div>
      <div className="cont">
        <div>Branch</div>
        <div>{bank.branch}</div>
      </div>
      <div className="cont">
        <div>IFSC</div>
        <div>{bank.ifsc}</div>
      </div>
    </div>
  );
  return (
    <div className="profile">
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-4 lg:col:4">
            <Message content={basicprofile} className="justify-content-left" style={{width:'100%', justifyContent:'left'}} />
          </div>
          <div className="col-12 md:col-4 lg:col:4">
          <Message content={userbank} className="justify-content-left" style={{width:'100%', justifyContent:'left'}} />
          </div>
          <div className="col-12 md:col-4 lg:col:4">TDS</div>
        </div>
      </div>
    </div>
  );
};
