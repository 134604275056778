import React, {  } from "react";
import "./inrwallet.scss";
import { TabView, TabPanel } from "primereact/tabview";
import { InrDepositComp } from "../../../components/usercomponent/inrcomponent/InrDepositComp";
import { InrWithdrawComp } from "../../../components/usercomponent/inrcomponent/InrWithdrawComp";
export const InrWallet = () => {
  return (
    <div className="inrwallet">
      <div className="content">
        <div className="activecoinaction">
          <TabView>
            <TabPanel header="Deposit" className="flex flex-column gap-2">
              <InrDepositComp />
            </TabPanel>
            <TabPanel header="Withdraw" className="flex flex-column gap-2">
                <InrWithdrawComp />
            </TabPanel>
          </TabView>
        </div>
      </div>
      
    </div>
  );
};
