import React, { useRef, useState } from "react";
import "./uploadkycdoc.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from '@mui/icons-material/Reply';
import img1 from "../../../images/pancard.png";
import img2 from "../../../images/adharfront.png";
import img3 from "../../../images/aadharback.png";
import img4 from "../../../images/checkbook.png";
import img5 from "../../../images/selfie.png";
import Cookie from "js-cookie";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as Yup from "yup";
import axios from "axios";
import { PreviewImage } from "../../../components/previewimage/PreviewImage";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
export const UploadKycDoc = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const [previewPan, setPreviewPan] = useState(null);
  const [previewAadhaarFront, setPreviewAadhaarFront] = useState(null);
  const [previewAadhaarBack, setPreviewAadhaarBack] = useState(null);
  const [previewCheque, setPreviewCheque] = useState(null);
  const [previewSelfy, setPreviewSelfy] = useState(null);
  const SUPPORTED_FORMAT = ["image/jpg", "image/jpeg"];
  const validationSchema = Yup.object({
    panimage: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
    aadharfrontimage: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
    aadharbackimage: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
    selfyimage: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
    chequeimage: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
  });
  const formik = useFormik({
    initialValues: {
      panimage: null,
      aadharfrontimage: null,
      aadharbackimage: null,
      selfyimage: null,
      chequeimage: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setPreviewSelfy(null)
      axios
        .post(
          url + "/user/uploaddocs",
          {
            uuid,
            panimage: values.panimage,
            aadharfrontimage: values.aadharfrontimage,
            aadharback: values.aadharbackimage,
            cheque: values.chequeimage,
            selfy:values.selfyimage
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": apikey,

            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.success,
            });
            setTimeout(function () {
              Navigate("/user/");
            }, 2000);
          }
        })
        .catch(function (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong!!!",
          });
        });
    },
  });
  const stepperRef = useRef(null);
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <div className="uploadkycdoc">
      <Toast ref={toast} />
      <div className="top card flex justify-content-center">
        <div style={{ flexBasis: "80rem" }}>
          <div className="flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>KYC DOCUMENTS UPLOAD</div>
          </div>
        </div>
      </div>
      <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
      <div className="card flex justify-content-center">
        <Stepper
          ref={stepperRef}
          style={{ flexBasis: "80rem", minHeight: "100vh" }}
        >
          <StepperPanel>
            <div className="flex flex-column h-20rem">
              <div className="flex align-items-center justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Pancard
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      const reader = new FileReader();
                      try {
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            formik.setFieldValue("panimage", e.target.files[0]);
                            formik.setFieldValue("pandata", reader.result);
                            setPreviewPan(reader.result);
                          }
                        };
                      } catch (error) {
                        console.log(error);
                      }
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                </Button>
                <div>PAN UPLOAD</div>
              </div>
              <div className="border-2 border-dashed surface-border border-round surface-ground">
                <div className="image">
                  {formik.values.panimage == null ? (
                    <img src={img1} alt="" />
                  ) : (
                    <PreviewImage
                      file={formik.values.panimage}
                      className="pan"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-4 justify-content-end">
              <Button
                variant="contained"
                size="small"
                disabled={previewPan===null?true:false}
                onClick={() => stepperRef.current.nextCallback()}
                startIcon={<SendIcon />}
              >
                Next
              </Button>
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-20rem">
              <div className="flex align-items-center justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Aadhaar Front
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      const reader = new FileReader();
                      try {
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            formik.setFieldValue(
                              "aadharfrontimage",
                              e.target.files[0]
                            );
                            formik.setFieldValue(
                              "aadharfrontdata",
                              reader.result
                            );
                            setPreviewAadhaarFront(reader.result);
                          }
                        };
                      } catch (error) {
                        console.log(error);
                      }
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                </Button>
                <div>PAN UPLOAD</div>
              </div>
              <div className="border-2 border-dashed surface-border border-round surface-ground">
                <div className="image">
                  {formik.values.aadharfrontimage == null ? (
                    <img src={img2} alt="" />
                  ) : (
                    <PreviewImage
                      file={formik.values.aadharfrontimage}
                      className="pan"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
            <Button
                type="button"
                variant="contained"
                size="small"
                onClick={() => stepperRef.current.prevCallback()}
                startIcon={<ReplyIcon />}
              >Back</Button>
              <Button
              type="button"
                variant="contained"
                size="small"
                disabled={previewAadhaarFront===null?true:false}
                onClick={() => stepperRef.current.nextCallback()}
                startIcon={<SendIcon />}
              >
                Next
              </Button>
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-20rem">
              <div className="flex align-items-center justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Aadhar Back
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      const reader = new FileReader();
                      try {
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            formik.setFieldValue(
                              "aadharbackimage",
                              e.target.files[0]
                            );
                            formik.setFieldValue(
                              "aadharbackdata",
                              reader.result
                            );
                            setPreviewAadhaarBack(reader.result);
                          }
                        };
                      } catch (error) {
                        console.log(error);
                      }
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                </Button>
                <div>PAN UPLOAD</div>
              </div>
              <div className="border-2 border-dashed surface-border border-round surface-ground">
                <div className="image">
                  {formik.values.aadharbackimage == null ? (
                    <img src={img3} alt="" />
                  ) : (
                    <PreviewImage
                      file={formik.values.aadharbackimage}
                      className="pan"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
            <Button
                type="button"
                variant="contained"
                size="small"
                onClick={() => stepperRef.current.prevCallback()}
                startIcon={<ReplyIcon />}
              >Back</Button>
              <Button
              type="button"
                variant="contained"
                size="small"
                disabled={previewAadhaarBack===null?true:false}
                onClick={() => stepperRef.current.nextCallback()}
                startIcon={<SendIcon />}
              >
                Next
              </Button>
            </div>
          </StepperPanel>

          <StepperPanel>
            <div className="flex flex-column h-20rem">
              <div className="flex align-items-center justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Cancel Cheque
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      const reader = new FileReader();
                      try {
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            formik.setFieldValue(
                              "chequeimage",
                              e.target.files[0]
                            );
                            formik.setFieldValue("chequedata", reader.result);
                            setPreviewCheque(reader.result);
                          }
                        };
                      } catch (error) {
                        console.log(error);
                      }
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                </Button>
                <div>CHEQUE</div>
              </div>
              <div className="border-2 border-dashed surface-border border-round surface-ground">
                <div className="image">
                  {formik.values.chequeimage == null ? (
                    <img src={img4} alt="" />
                  ) : (
                    <PreviewImage
                      file={formik.values.chequeimage}
                      className="pan"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
            <Button
                type="button"
                variant="contained"
                size="small"
                onClick={() => stepperRef.current.prevCallback()}
                startIcon={<ReplyIcon />}
              >Back</Button>
              <Button
              type="button"
                variant="contained"
                size="small"
                disabled={previewCheque===null?true:false}
                onClick={() => stepperRef.current.nextCallback()}
                startIcon={<SendIcon />}
              >
                Next
              </Button>
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-20rem">
              <div className="flex align-items-center justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Selfy
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      const reader = new FileReader();
                      try {
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            formik.setFieldValue(
                              "selfyimage",
                              e.target.files[0]
                            );
                            formik.setFieldValue("selfydata", reader.result);
                            setPreviewSelfy(reader.result);
                          }
                        };
                      } catch (error) {
                        console.log(error);
                      }
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                </Button>
                <div>Selfy</div>
              </div>
              <div className="border-2 border-dashed surface-border border-round surface-ground">
                <div className="image">
                  {formik.values.selfyimage == null ? (
                    <img src={img5} alt="" />
                  ) : (
                    <PreviewImage
                      file={formik.values.selfyimage}
                      className="selfy"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
            <Button
                type="button"
                variant="contained"
                size="small"
                onClick={() => stepperRef.current.prevCallback()}
                startIcon={<ReplyIcon />}
              >Back</Button>
              <Button
              type="submit"
                variant="contained"
                size="small"
                disabled={previewSelfy===null?true:false}
                onClick={() => stepperRef.current.nextCallback()}
                startIcon={<SendIcon />}
              >
                Submit Uploads
              </Button>
            </div>
          </StepperPanel>
        </Stepper>
      </div>
      </Form>
      </FormikProvider>
    </div>
  );
};
