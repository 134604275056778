import React from 'react'
import { MasterTradeBuy } from './MasterTradeBuy'
import { MasterTradeSell } from './MasterTradeSell'

export const MasterTrade = () => {
  return (
    <div className='mastertrade'>
        <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
            <MasterTradeBuy />
        </div>
        <div className="col-12 md:col-12 lg:col-6 left">
            <MasterTradeSell />
        </div>
        </div>
    </div>
  )
}
