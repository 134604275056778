// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadkyc {
  min-height: 100vh;
  padding: 80px 20px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/uploadkyc/uploadkyc.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,kBAAA;EAOI,yDAAA;EACA,4BAAA;AANN","sourcesContent":["@import \"../../../style.scss\";\n.uploadkyc {\n  min-height: 100vh;\n  padding: 80px 20px;\n    // background: rgb(76, 113, 164);\n    //   background: linear-gradient(\n    //     180deg,\n    //     rgba(76, 113, 164, 1) 0%,\n    //     rgba(196, 240, 233, 0) 100%\n    //   );\n      background-image: url(\"../../../../public/images/frontcover.png\");\n      background-repeat: no-repeat;\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
