import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import Cookie from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import "./mobileverification.scss";
export const MobileVerification = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [btnotp, setBtnOtp] = useState(0);
  const [btnsubmit, setBtnSubmit] = useState(0);
  const [selectedCity, setSelectedCity] = useState("91");
  const cities = [
    { name: "IND", code: "91" },
    { name: "NEPAL", code: "977" },
  ];
  const validationSchema = Yup.object({
    uuid: Yup.mixed().required(),
    otp: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    mobile: Yup.string().required("Invalid Mobile No."),
  });
  const formik = useFormik({
    initialValues: {
      uuid,
      mobile: "",
      otp: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnSubmit(2)
      const resp = await axios.post(
        url + "/user/mobauthcomplete",
        {
          selectedCity,
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        setTimeout(function () {
          Navigate("/user/");
        }, 3000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const sendOtp = async () => {
    setBtnOtp(1);
    var mobile = formik.values.mobile;
    const resp = await axios.post(
      url + "/user/mobauthinit",
      {
        uuid,
        selectedCity,
        mobile,
        otp_for: "Mobile",
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      setBtnOtp(2)
      setBtnSubmit(1)
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
      setBtnOtp(0)
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="mobileverification">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="rounded-0">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/logo.png" width="100" alt="" />
                    <h3>MOBILE VERIFICATION</h3>
                    <div className="component">
                      <div className="p-inputgroup flex-1">
                        <Dropdown
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.value)}
                          options={cities}
                          optionLabel="code"
                          optionValue="code"
                          placeholder="Country Code"
                          className="w-8rem md:w-10rem"
                        />
                        <InputText
                          style={{ width: "100%" }}
                          name="mobile"
                          keyfilter={"pint"}
                          placeholder="99330XXXXX"
                          autoFocus
                          onChange={formik.handleChange}
                          value={formik.values.mobile}
                        />
                        {getFormErrorMessage("mobile")}
                      </div>
                    </div>
                    <div className="component">
                      <Button
                        type="button"
                        label="Send OTP"
                        disabled={btnotp===0?false:true}
                        onClick={() => sendOtp()}
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="text"
                        name="otp"
                        placeholder="OTP"
                        keyfilter={"pint"}
                        disabled={btnotp===0?true:false}
                        onChange={formik.handleChange}
                        value={formik.values.otp}
                        toggleMask
                      />
                      {getFormErrorMessage("otp")}
                    </div>
                    <div className="component">
                      <Button type="submit" label="Submit" size="large" disabled={btnsubmit===0?true:false} />
                    </div>
                  </div>
                </Form>
              </FormikProvider>
              <Message text="Please use registered Mobile No." className="mt-3" style={{width:'100%'}} />
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
