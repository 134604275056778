import React, { useRef, useState } from "react";
import "./uploadkyc.scss";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import axios from "axios";
import Cookie from "js-cookie";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useNavigate } from "react-router-dom";
export const UploadKyc = () => {
  const Navigate = useNavigate();
  const toast = useRef(null);
  const stepperRef = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const [aadhar, setAadhar] = useState("123");
  const [aadharName, setAadharName] = useState("123");
  const [aadharCheck, setAadharCheck] = useState(1);
  const [pan, setPan] = useState("123");
  const [panStatus, setPanStatus] = useState("123");
  const [panId, setPanId] = useState("123");
  const [panCheck, setPanCheck] = useState(1);
  const [account, setAccount] = useState("123");
  const [ifsc, setIfsc] = useState("123");
  const [accountCheck, setAccountCheck] = useState(1);
  const [branch, setBranch] = useState("123");
  const [bankName, setBankName] = useState("123");
  const [btnSubmit, setBtnSubmit] = useState(0);
  const fetchAadhar = async () => {
    setAadharCheck(1);
    const resp = await axios.post(
      url + "/user/getaadhaar",
      {
        uuid,
        aadhar,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setAadharName(resp.data.aadhaarname);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!! Contact Support",
      });
      setAadharCheck(0);
    }
  };
  const fetchPan = async () => {
    setPanCheck(1);
    const resp = await axios.post(
      url + "/user/getpan",
      {
        uuid,
        pan,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setPanStatus(resp.data.panname);
      setPanId(resp.data.client_id);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!! Contact Support",
      });
      setPanCheck(0);
    }
  };
  const fetchBank = async () => {
    setAccountCheck(1);
    const resp = await axios.post(
      url + "/user/getbank",
      {
        uuid,
        account,
        ifsc,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setBranch(resp.data.branch);
      setBankName(resp.data.bankname);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!! Contact Support",
      });
      setAadharCheck(0);
    }
  };
  const submitData = async () => {
    setBtnSubmit(1);
    if (aadharName === "" || panStatus === "" || branch === "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong!! Contact Support",
      });
      setBtnSubmit(0);
    } else {
      const resp = await axios.post(
        url + "/user/submitkyc",
        {
          uuid,
          user_name: panStatus,
          panId,
          aadhar,
          pan,
          account,
          bankName,
          branch,
          ifsc,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Profile Updated Successfully",
        });
        setBtnSubmit(2);
        setTimeout(function () {
          Navigate("/user/");
        }, 5000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong!! Contact Support",
        });
        setBtnSubmit(0);
      }
    }
  };
  const gotoDashBoard = () => {
    Navigate("/user");
  };
  return (
    <div className="uploadkyc">
      <Toast ref={toast} />
      <div className="top card flex justify-content-center">
        <div style={{ flexBasis: "80rem" }}>
          <div className="flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>KYC DETAILS</div>
            <Button icon="pi pi-home" size="small" />
          </div>
        </div>
      </div>
      <div className="card flex justify-content-center">
        <Stepper
          ref={stepperRef}
          style={{ flexBasis: "80rem", minHeight: "100vh" }}
        >
          <h1>No Panel</h1>
          <StepperPanel>
            <div className="flex flex-column h-30rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex flex-column justify-content-left p-2 font-medium gap-2">
                <div className="flex justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                  <div>Enter Aadhaar No</div>
                  <div>Checked: 0/3</div>
                </div>
                <InputText
                  keyfilter={"pint"}
                  placeholder="Aadhaar No"
                  value={aadhar}
                  onChange={(e) => setAadhar(e.target.value)}
                  disabled={aadharCheck === 0 ? false : true}
                />
                <Button
                  label="Fetch Details"
                  onClick={() => fetchAadhar()}
                  disabled={aadharCheck === 0 ? false : true}
                />
                <Divider />
                <div>Fetched State</div>
                <InputText
                  keyfilter={"pint"}
                  placeholder="State on Aadhaar"
                  value={aadharName}
                  disabled
                />
              </div>
            </div>
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                disabled={aadharCheck === 0 ? true : false}
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-30rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex flex-column justify-content-left p-2 font-medium gap-2">
                <div className="flex justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                  <div>Enter PAN No</div>
                  <div>Checked: 0/3</div>
                </div>
                <InputText
                  keyfilter={"alphanum"}
                  placeholder="Pan No"
                  value={pan}
                  onChange={(e) => setPan(e.target.value)}
                  disabled={panCheck === 0 ? false : true}
                  style={{ textTransform: "uppercase" }}
                />
                <Button
                  label="Fetch Details"
                  onClick={() => fetchPan()}
                  disabled={panCheck === 0 ? false : true}
                />
                <Divider />
                <div>Fetched Name</div>
                <InputText
                  placeholder="Name on Pan"
                  value={panStatus}
                  disabled
                />
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                disabled={panCheck === 0 ? true : false}
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-30rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex flex-column justify-content-left p-2 font-medium gap-2">
                <div className="flex justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                  <div>Enter Account No/IFSC</div>
                  <div>Checked: 0/3</div>
                </div>
                <InputText
                  keyfilter={"pint"}
                  placeholder="Account No"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                  disabled={accountCheck === 0 ? false : true}
                />
                <InputText
                  keyfilter={"alphanum"}
                  placeholder="IFSC"
                  value={ifsc}
                  onChange={(e) => setIfsc(e.target.value)}
                  disabled={accountCheck === 0 ? false : true}
                  style={{ textTransform: "uppercase" }}
                />
                <Button
                  label="Fetch Details"
                  onClick={() => fetchBank()}
                  disabled={accountCheck === 0 ? false : true}
                />
                <Divider />
                <div>Fetched Name</div>
                <InputText placeholder="Branch" value={branch} disabled />
                <InputText placeholder="Bank Name" value={bankName} disabled />
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                disabled={accountCheck === 0 ? true : false}
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
          <StepperPanel>
            <div className="flex flex-column h-30rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex flex-column justify-content-left p-2 font-medium gap-2">
                <div className="flex justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2">
                  <div>Preview Details</div>
                  <div>Confirm</div>
                </div>
                <InputText placeholder="Branch" value={panStatus} disabled />
                <InputText placeholder="Bank Name" value={aadhar} disabled />
                <InputText placeholder="Bank Name" value={pan} disabled />
                <InputText placeholder="Bank Name" value={account} disabled />
                <InputText placeholder="Bank Name" value={bankName} disabled />
                <InputText placeholder="Bank Name" value={branch} disabled />
                <InputText
                  placeholder="Bank Name"
                  value={ifsc}
                  disabled
                  style={{ textTransform: "uppercase" }}
                />
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              {btnSubmit === 0 ? (
                <Button
                  label="Submit"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => submitData()}
                />
              ) : (
                <>
                  {btnSubmit === 1 ? (
                    <Button
                      label="Submittimg..."
                      icon="pi pi-arrow-right"
                      iconPos="right"
                    />
                  ) : (
                    <Button
                      label="Dashboard"
                      icon="pi pi-arrow-right"
                      iconPos="right"
                      onClick={() => gotoDashBoard()}
                    />
                  )}
                </>
              )}
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
};
