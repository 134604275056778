// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admwalletbalance {
  min-height: 100vh;
  padding: 80px 20px;
  background: rgb(76, 113, 164);
  background: linear-gradient(180deg, rgb(76, 113, 164) 0%, rgba(196, 240, 233, 0) 100%);
}
.admwalletbalance .left .form-group .input {
  display: flex;
  align-items: center;
  gap: 3px;
}
.admwalletbalance .left .form-group .creditbal {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.admwalletbalance .left .form-group .creditbal .cont {
  align-items: center;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/userwalletbalance/admwalletbalance.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,kBAAA;EACA,6BAAA;EACA,sFAAA;AAAF;AAOQ;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AALZ;AAOQ;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AALZ;AAMY;EACI,mBAAA;EACA,aAAA;EACA,8BAAA;AAJhB","sourcesContent":["@import \"../../../style.scss\";\n.admwalletbalance {\n  min-height: 100vh;\n  padding: 80px 20px;\n  background: rgb(76, 113, 164);\n  background: linear-gradient(\n    180deg,\n    rgba(76, 113, 164, 1) 0%,\n    rgba(196, 240, 233, 0) 100%\n  );\n  .left{\n    .form-group{\n        .input{\n            display: flex;\n            align-items: center;\n            gap: 3px;\n        }\n        .creditbal{\n            display: flex;\n            flex-direction: column;\n            gap: 5px;\n            .cont{\n                align-items: center;\n                display: flex;\n                justify-content: space-between;\n            }\n        }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
