import React, { useEffect, useRef, useState } from "react";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import Cookie from "js-cookie";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
export const InrDepositComp = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const [visible, setVisible] = useState(false);
  const [deposits, setDeposits] = useState({});
  const [btnSubmit, setBtnSubmit] = useState(0)
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getinrtransactions",
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setDeposits(resp.data.inrdeposit);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    uuid: Yup.string().required(),
    tranid: Yup.mixed("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number("Invalid Amount, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      uuid: uuid,
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnSubmit(1);
      const resp = await axios.post(
        url + "/user/setinrdeposit",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
        setBtnSubmit(2);
        const resp1 = await axios.post(
          url + "/user/getinrtransactions",
          {
            uuid,
          },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        );
        if (resp1.status === 200) {
          setDeposits(resp1.data.inrdeposit);
        }
        // Navigate("/user");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setBtnSubmit(0);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const Copydepostaddress = () => (
    <Button
      icon="pi pi-building-columns"
      size="small"
      onClick={() => setVissible()}
    />
  );
  const setVissible = () => {
    setVisible(true);
  };
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Content copied successfully.",
    });
  };
  const inrdeposit = (
    <DataTable value={deposits} size="small" style={{ width: "100%" }}>
      <Column field="tran_date" header="Date"></Column>
      <Column field="amount" header="Amount"></Column>
      <Column field="status" header="Status"></Column>
      <Column field="reject_reason" header="Reason"></Column>
    </DataTable>
  );
  return (
    <div className="inrwithdraw flex flex-column gap-2">
      <Toast ref={toast} />
      <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
        Bank Details
      </div>
      <div className="depaddress flex align-items-center justify-content-between">
        <div className="add flex align-items-center justify-content-between gap-1">
          <Message
            severity="warn"
            text="Deposit Only in Registered Bank Account"
          />
          <Copydepostaddress />
        </div>
      </div>
      <Message
        severity="error"
        text="Please do not make UPI Transactions."
        style={{justifyContent:"left"}}
      />
      <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
        Deposit Details
      </div>
      <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group flex flex-column gap-2">
              <InputText
                name="tranid"
                keyfilter={"pint"}
                className="p-inputtext-sm w-100"
                placeholder="UTR/Tran Id"
                value={formik.values.tranid}
                onChange={formik.handleChange}
                size={"small"}
              />
              {getFormErrorMessage("tranid")}
              <InputText
                name="amount"
                keyfilter={"pint"}
                className="p-inputtext-sm"
                placeholder="Amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                size={"small"}
              />
              {getFormErrorMessage("amount")}
              {btnSubmit===0?
              <Button type="submit" label="Confirm" size="small" />
              :
              <>
              {btnSubmit===1?
              <Button type="button" label="Submitting..." size="small" />
              :
              <Button type="button" label="Submitted" size="small" />
              }
              </>
              }
              
            </div>
          </Form>
        </FormikProvider>
      </div>
      <Message content={inrdeposit} className="w-100" />
      <Dialog
        header="Official Bank Details"
        visible={visible}
        style={{ width: "100vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="bankdetails flex flex-column gap-1">
          <div className="cont flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>SOARXBITS PVT. LTD.</div>
            <Button
              icon="pi pi-building-columns"
              size="small"
              onClick={() => copyText("SOARXBITS PVT. LTD.")}
            />
          </div>
          <div className="cont flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>Siliguri</div>
            <Button
              icon="pi pi-building-columns"
              size="small"
              onClick={() => copyText("Siliguri")}
            />
          </div>
          <div className="cont flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>121163700000075</div>
            <Button
              icon="pi pi-building-columns"
              size="small"
              onClick={() => copyText("121163700000075")}
            />
          </div>
          <div className="cont flex justify-content-between align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
            <div>YESB0001211</div>
            <Button
              icon="pi pi-building-columns"
              size="small"
              onClick={() => copyText("YESB0001211")}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
