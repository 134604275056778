// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeappbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}
.homeappbar .p-menubar {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.homeappbar .p-button-label {
  font-weight: 500;
}
.homeappbar .headlogo {
  font-weight: 900;
  font-size: 20px;
}
@media (max-width: 900.1px) {
  .homeappbar .p-menubar {
    padding: 20px 5px;
  }
  .homeappbar .p-menubar-button {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/homecomponent/homeappbar.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;AAAJ;AACI;EAEE,gBAAA;EACA,cAAA;EACA,eAAA;AAAN;AAKI;EACE,gBAAA;AAHN;AAKI;EACE,gBAAA;EACA,eAAA;AAHN;AAMI;EACE;IAGE,iBAAA;EANN;EAQI;IACE,aAAA;EANN;AACF","sourcesContent":["@import \"../../style.scss\";\n.homeappbar {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 1001;\n    .p-menubar {\n      // border: none;\n      border-radius: 0;\n      border-left: 0;\n      border-right: 0;\n\n     \n      // padding: 20px 80px;\n    }\n    .p-button-label {\n      font-weight: 500;\n    }\n    .headlogo {\n      font-weight: 900;\n      font-size: 20px;\n    }\n    $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      .p-menubar {\n        // border: none;\n        //background-color: transparent;\n        padding: 20px 5px;\n      }\n      .p-menubar-button {\n        display: none;\n      }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
