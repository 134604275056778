// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activepair .bq .base {
  font-size: 16px;
  font-weight: 700;
}
.activepair .bq .quote {
  font-size: 14px;
  font-weight: 700;
}
.activepair .cont .fs12 {
  font-size: 12px;
}
.activepair .cont .fs14 {
  font-size: 16px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/usercomponent/tradecomponent/activepair.scss"],"names":[],"mappings":"AAGI;EACE,eAAA;EACA,gBAAA;AAFN;AAII;EACI,eAAA;EACA,gBAAA;AAFR;AAMI;EACI,eAAA;AAJR;AAMI;EACI,eAAA;EACA,gBAAA;AAJR","sourcesContent":["@import \"../../../style.scss\";\n.activepair {\n  .bq {\n    .base {\n      font-size: 16px;\n      font-weight: 700;\n    }\n    .quote{\n        font-size: 14px;\n        font-weight: 700;\n    }\n  }\n  .cont{\n    .fs12{\n        font-size: 12px;\n    }\n    .fs14{\n        font-size: 16px;\n        font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
