// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spottrading {
  padding: 80px 20px;
  background: rgb(76, 113, 164);
  background: linear-gradient(180deg, rgb(76, 113, 164) 0%, rgba(196, 240, 233, 0) 100%);
}
.spottrading .p-tabview-panels {
  height: 400px;
}
.spottrading .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
  display: flex;
  justify-content: space-between;
}
.spottrading .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li {
  width: 33%;
  text-align: center;
}
.spottrading .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li a {
  justify-content: center;
}
.spottrading .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-tabview-selected #pr_id_2_header_1 {
  background-color: rgb(189, 100, 100);
  color: rgb(35, 29, 29);
}
.spottrading .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-tabview-selected #pr_id_2_header_0 {
  background-color: rgb(112, 230, 230);
  color: rgb(22, 16, 16);
}
.spottrading .p-tabview-nav-container .p-tabview-panel {
  display: flex;
  gap: 10px;
}
@media (max-width: 900.1px) {
  .spottrading .first {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/user/spottrading/spottrading.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,6BAAA;EACA,sFAAA;AAAF;AAKE;EACE,aAAA;AAHJ;AAOM;EACE,aAAA;EACA,8BAAA;AALR;AAMQ;EACE,UAAA;EACA,kBAAA;AAJV;AAMU;EACE,uBAAA;AAJZ;AAQU;EACE,oCAAA;EACA,sBAAA;AANZ;AAQU;EACE,oCAAA;EACA,sBAAA;AANZ;AAWI;EACE,aAAA;EACA,SAAA;AATN;AAaI;EACE;IACE,aAAA;EAXN;AACF","sourcesContent":["@import \"../../../style.scss\";\n.spottrading {\n  padding: 80px 20px;\n  background: rgb(76, 113, 164);\n  background: linear-gradient(\n    180deg,\n    rgba(76, 113, 164, 1) 0%,\n    rgba(196, 240, 233, 0) 100%\n  );\n  .p-tabview-panels{\n    height: 400px;\n  }\n  .p-tabview-nav-container {\n    .p-tabview-nav-content {\n      .p-tabview-nav {\n        display: flex;\n        justify-content: space-between;\n        li {\n          width: 33%;\n          text-align: center;\n\n          a {\n            justify-content: center;\n          }\n        }\n        .p-tabview-selected {\n          #pr_id_2_header_1 {\n            background-color: rgb(189, 100, 100);\n            color: rgb(35, 29, 29);\n          }\n          #pr_id_2_header_0 {\n            background-color: rgb(112, 230, 230);\n            color: rgb(22, 16, 16);\n          }\n        }\n      }\n    }\n    .p-tabview-panel{\n      display: flex;\n      gap: 10px;\n    }\n  }\n  $breakpoint-tablet: 900.1px;\n    @media (max-width: $breakpoint-tablet) {\n      .first{\n        display: none;\n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
