import React, { useEffect, useRef, memo } from "react";

export const TickerWidget = () => {
  const container = useRef();
  const initialized = useRef(false)
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
            {
  "symbols": [
    {
      "description": "",
      "proName": "BINANCE:BTCUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:ETHUSD"
    },
    {
      "description": "",
      "proName": "BINANCE:BNBUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:XRPUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:TRXUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:DOGEUSDT"
    }
  ],
  "showSymbolLogo": true,
  "isTransparent": false,
  "displayMode": "adaptive",
  "colorTheme": "dark",
  "locale": "en"
}`;
    if (!initialized.current) {
        initialized.current = true
        container.current.appendChild(script);
      }
  }, []);
  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
};
