import React, { useEffect, useState } from "react";
import "./admwalletbalance.scss";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
// import { CustomerService } from './service/CustomerService';
import axios from "axios";
export const AdmWalletBalance = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [platforId, setPlatformId] = useState("");
  const [coin, setCoin] = useState("");
  const [tranType, setTranType] = useState("");
  const [amount, setAmount] = useState("");
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [representatives] = useState([
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Anna Fali", image: "annafali.png" },
    { name: "Asiya Javayant", image: "asiyajavayant.png" },
    { name: "Bernardo Dominic", image: "bernardodominic.png" },
    { name: "Elwin Sharvill", image: "elwinsharvill.png" },
    { name: "Ioni Bowcher", image: "ionibowcher.png" },
    { name: "Ivan Magalhaes", image: "ivanmagalhaes.png" },
    { name: "Onyama Limba", image: "onyamalimba.png" },
    { name: "Stephen Shaw", image: "stephenshaw.png" },
    { name: "XuXue Feng", image: "xuxuefeng.png" },
  ]);
  const [statuses] = useState([
    "unqualified",
    "qualified",
    "new",
    "negotiation",
    "renewal",
  ]);

  // const getSeverity = (status) => {
  //   switch (status) {
  //     case "unqualified":
  //       return "danger";

  //     case "qualified":
  //       return "success";

  //     case "new":
  //       return "info";

  //     case "negotiation":
  //       return "warning";

  //     case "renewal":
  //       return null;
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getuser",{
        headers: {
          "x-api-key": apikey,
        },
      })
      if(resp.status===200){
        setCustomers(getCustomers(resp.data.userdata));
        // setCustomers(resp.data.userdata);
        // setCustomers(resp.data.userdata);
        setLoading(false);
      }
    }
    fetchData()
    // CustomerService.getCustomersMedium().then((data) => {
    //     setCustomers(getCustomers(data));
    //     setLoading(false);
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          style={{ width: "24px" }}
        />
        <span>{rowData.country.name}</span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`}
          width="32"
        />
        <span>{option.name}</span>
      </div>
    );
  };

  // const statusBodyTemplate = (rowData) => {
  //   return (
  //     <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
  //   );
  // };

  // const statusItemTemplate = (option) => {
  //   return <Tag value={option} severity={getSeverity(option)} />;
  // };

  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle": rowData.verified,
          "false-icon pi-times-circle": !rowData.verified,
        })}
      ></i>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        // itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const verifiedRowFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const header = renderHeader();

  const handleSubmit = async () => {
    const resp = await axios.post(
      url + "/admin/creditbalance",
      {
        platforId,
        coin,
        tranType,
        amount,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
  };
  return (
    <div className="admwalletbalance">
      <div className="content">
        <div className="grid">
          <div className="col-5 left">
            <Card>
              <div className="form-group">
                <div className="input">
                  <div>Platform Id</div>
                  <InputText
                    className="p-inputtext-sm"
                    size="small"
                    placeholder="Platform Id"
                    value={platforId}
                    onChange={(e) => setPlatformId(e.target.value)}
                  />
                  <Button size="small" label="Search" />
                </div>
                <Divider />
                <div className="creditbal">
                  <div className="cont">
                    <div>Coin</div>
                    <div>
                      <InputText
                        className="p-inputtext-sm"
                        placeholder="Currency"
                        value={coin}
                        onChange={(e) => setCoin(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="cont">
                    <div>Type</div>
                    <div>
                      <InputText
                        className="p-inputtext-sm"
                        placeholder="DR/CR"
                        value={tranType}
                        onChange={(e) => setTranType(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="cont">
                    <div>Amount</div>
                    <div>
                      <InputText
                        className="p-inputtext-sm"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="cont w-full">
                    <Button
                      size="small"
                      className="w-full"
                      label="Confirm"
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-7">
            <Card>
              <DataTable
                value={customers}
                paginator
                rows={20}
                dataKey="id"
                filters={filters}
                size="small"
                // filterDisplay="row"
                loading={loading}
                globalFilterFields={[
                  "first_name",
                  "platform_id",
                  "phone_number"
                ]}
                header={header}
                emptyMessage="No customers found."
              >
                <Column
                  field="first_name"
                  header="Name"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  header="Platform Id"
                  style={{ minWidth: "12rem" }}
                  field="platform_id"
                />
                <Column
                  header="Email"
                  filterField="representative"
                  showFilterMenu={false}
                  filterMenuStyle={{ width: "14rem" }}
                  style={{ minWidth: "14rem" }}
                  field="email"
                  filter
                  filterElement={representativeRowFilterTemplate}
                />
                <Column
                  field="phone_number"
                  header="Mobile"
                  showFilterMenu={false}
                  filterMenuStyle={{ width: "14rem" }}
                  style={{ minWidth: "12rem" }}
                  // body={statusBodyTemplate}
                  filter
                  filterElement={statusRowFilterTemplate}
                />
                
              </DataTable>
              {/* <DataTable value={customers} tableStyle={{ minWidth: '50rem' }}>
                <Column field="code" header="Code"></Column>
                <Column field="name" header="Name"></Column>
                <Column field="category" header="Category"></Column>
                <Column field="quantity" header="Quantity"></Column>
            </DataTable> */}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
