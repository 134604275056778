import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import Cookie from "js-cookie";
import "./login.scss";
export const AdminLogin = () => {
    const toast = useRef(null);
  const Navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [btnEmailOTP, setBtnEmailOTP] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const hostPath = process.env.REACT_APP_URL;
  const [visible, setVisible] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      authcode: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/adminlogin",
        {
          checked,
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        Cookie.set('srxadmin', 'srxadmin')
        // let token = resp.data.srxtoken;
        // //token = token.split(".")[1];
        // Cookie.set("srxtoken", token, {
        //   expires: new Date(new Date().getTime() + 60 * 300 * 1000),
        // });
        Navigate("/admin");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const sendEmailOTP = () => {};
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="login">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="rounded-0">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/logo.png" width="100" alt="" />
                    <h3>USER LOG IN</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        toggleMask
                      />
                      {getFormErrorMessage("password")}
                    </div>

                    <div className="component">
                      <div className=" flex align-items-center">
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>
                        <label htmlFor="ingredient1" className="ml-2">
                          {checked === true ? "Google Auth Code" : "Email OTP"}
                        </label>
                      </div>
                    </div>
                    {checked === false ? (
                      <div className="component">
                        {btnEmailOTP === 0 ? (
                          <Button
                            icon="pi pi-send"
                            label="Get Email OTP"
                            onClick={() => sendEmailOTP()}
                          />
                        ) : (
                          <>
                            {btnEmailOTP === 1 ? (
                              <Button
                                icon="pi pi-send"
                                label="Email OTP Sending"
                              />
                            ) : (
                              <Button icon="pi pi-send" label="Email Sent" />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="component">
                      <InputText
                        type="text"
                        name="authcode"
                        placeholder={
                          checked === true ? "Google Auth Code" : "Email OTP"
                        }
                        onChange={formik.handleChange}
                        value={formik.values.authcode}
                      />
                      {getFormErrorMessage("authcode")}
                    </div>

                    <div className="component">
                      <Button type="submit" label="Submit" size="large" />
                    </div>
                    <Divider />
                    <div className="component">
                      <Link to="/forgetpassword">Forget Password?</Link>
                    </div>
                    <div className="component">
                      <p>
                        Don't have account? <Link to="/signup">Sign Up</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
