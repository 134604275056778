import React, { useEffect, useState } from "react";
import "./cryptodeposit.scss";
import Cookie from "js-cookie";
import axios from "axios";
import {Link} from 'react-router-dom'
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
export const CryptoDeposit = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const token = Cookie.get("srxtoken");
  const uuid = Cookie.get("srxuuid");
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getcryptodetails",
        { uuid},
        {
          headers: {
            "x-api-key": apikey,
            "act-sessionid": token,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.currency);
        //setPrice(resp.data.ticker.high);
      }
    };
    fetchData();
  }, []);
  const cursymbol = (data) => {
    return (
      <div className="flex align-items-center gap-1">
      <img src={data.data[0].logo} alt="" width={30} />
      <div>{data.currency.toUpperCase()}</div>
      </div>
    )
  }
  const coinaction = (data) => {
    return (
      <div className="flex align-items-center gap-3">
        <Link to={`/user/spotwallet/${data.currency}`}><i className="pi pi-download" /></Link>
        <Link><i className="pi pi-upload" /></Link>
        {/* <Link to={`/user/spottrading/soarxinr`}><i className="pi pi-arrow-right-arrow-left" /></Link> */}
      </div>
    )
  }
  const balance = (data) => {
    return (
      <div className="flex align-items-center gap-3">
        {(data.balance/10e7).toFixed(3)}
      </div>
    )
  }
  const frozen = (data) => {
    return (
      <div className="flex align-items-center gap-3">
        {(data.frozen_balance/10e7).toFixed(3)}
      </div>
    )
  }
  return (
    <div className="cryptodeposit">
      <div className="content">
        <DataTable value={data} size="small">
          <Column body={cursymbol} header="Coin/Token"></Column>
          <Column field={balance} header="Balance"></Column>
          <Column field={frozen} header="In Trade"></Column>
          <Column body={coinaction} header="Action"></Column>
        </DataTable>
      </div>
    </div>
  );
};
