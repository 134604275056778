import React, { useState } from "react";
import img1 from "../../images/slide-1.webp";
import "./previewimg.scss";
export const PreviewImage = ({ file, imagetype, className }) => {
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  try {
    var data = reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  } catch (error) {
    console.log(error);
  }
  return (
    <div className="imgcontainer">
      {preview == null ? (
        <img src={img1} alt="" className={className} />
      ) : (
        <img src={preview} alt="" className={className} />
      )}
    </div>
  );
};
