import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import axios from "axios";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Galleria } from "primereact/galleria";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import "./pendingkyc.scss";
import { InputText } from "primereact/inputtext";
export const PendingKyc = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState();
  const [dataKyc, setDataKyc] = useState();
  const [images, setImages] = useState(null);
  const [btnPending, setBtnPending] = useState(0)
  const [visibleReject, setVisibleReject] = useState(false);
  const [rejectId, setRejectId] = useState("")
  const [reason, setReason] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getpendingkyc", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.users);
      }
    };
    fetchData();
  }, []);
  const [selectedReject, setSelectedReject] = useState(null);
    const rejects = [
        { name: 'Document Level', code: '3' },
        { name: 'KYC Level', code: '2' },
    ];
  const approve = (rowData) => {
    return (
      <>
        <Button
          label="Approve"
          disabled={btnPending===0?false:true}
          onClick={() => confirmApprove(rowData.uuid)}
          size="small"
        />
      </>
    );
  };
  const confirmApprove = async (uuid) => {
    setBtnPending(1)
    const resp = await axios.post(
      url + "/admin/approvekyc",
      { uuid },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      window.location.reload(true);
    }
  };
  const confirmReject =async () =>{
    if(!selectedReject){
      return false
    }
    const resp = await axios.post(
      url + "/admin/rejectKyc",
      {
        rejectId,
        reason,
        selectedReject
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      setVisibleReject(false)
      setSelectedReject(null)
      setReason("")
      window.location.reload(true);
    }
  }
  const footerContent = (
    <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setVisibleReject(false)} className="p-button-text" />
        <Button label="Confirm" icon="pi pi-check" onClick={() => confirmReject()} autoFocus />
    </div>
);
  const rejectKyc = (platformId) =>{
    setRejectId(platformId)
    setVisibleReject(true)
  }
  const reject = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          severity="warning"
          size="small"
          onClick={() => rejectKyc(rowData.platform_id)}
        />
      </>
    );
  };
  const paused = (rowData) => {
    return (
      <>
        <Button label="Paused" size="small" />
      </>
    );
  };
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];
  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        alt={item.alt}
        style={{ width: "800px", height:'300px', display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        alt={item.alt}
        style={{ width:'50px', display: "block" }}
      />
    );
  };
  const check = (rowData) => {
    return (
      <Button
        label="Check"
        size="small"
        onClick={() => setImageData(rowData.platform_id)}
      />
    );
  };
  const caption = (item) => {
    return (
      <React.Fragment>
        <div className="text-xl font-bold">{item.title}</div>
      </React.Fragment>
    );
  };
  const setImageData = async (platformid) => {
    const resp = await axios.post(url + "/admin/getkycdetails",{ platformid }, {
      headers: {
        "x-api-key": apikey,
      },
    });
    if (resp.status === 200) {
      setDataKyc(resp.data.userdata);
      if(dataKyc){
        setImages([{
          itemImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/panimage.jpg`,
          thumbnailImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/panimage.jpg`,
          alt: "PAN",
          title: `${dataKyc[0].pan}/${dataKyc[0].user_name}`,
        },{
          itemImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/aadharfrontimage.jpg`,
          thumbnailImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/aadharfrontimage.jpg`,
          alt: "AADHAAR FRONT",
          title: `${dataKyc[0].aadhar}/${dataKyc[0].user_name}`,
        },{
          itemImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/aadharback.jpg`,
          thumbnailImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/aadharback.jpg`,
          alt: "AADHAAR BACK",
          title: `${dataKyc[0].aadhar}/${dataKyc[0].user_name}`,
        },{
          itemImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/cheque.jpg`,
          thumbnailImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/cheque.jpg`,
          alt: "BANK",
          title: `${dataKyc[0].data[0].bank}/${dataKyc[0].data[0].accno}/${dataKyc[0].data[0].ifsc}`,
        },{
          itemImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/selfy.jpg`,
          thumbnailImageSrc:`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformid}/selfy.jpg`,
          alt: "NAME",
          title: `${dataKyc[0].user_name}`,
        }]);
        setVisible(true)
      }
      
    }
    
  };
  
  
  
  return (
    <div className="pendingkyc">
      <div className="content">
        <DataTable value={data} size="small">
          <Column field="platform_id" header="Id"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="first_name" header="Name"></Column>
          <Column field="phone_number" header="Mobile"></Column>
          <Column field={check} header="Check"></Column>
          <Column field={approve} header="Approve"></Column>
          <Column field={reject} header="Reject"></Column>
          <Column field={paused} header="Paused"></Column>
        </DataTable>
      </div>
      <Dialog
        header={`KYC DOCS`}
        visible={visible}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="card">
          <Galleria
            value={images}
            responsiveOptions={responsiveOptions}
            numVisible={1}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
            caption={caption}
            style={{ maxWidth: "640px" }}
          />
        </div>
      </Dialog>
      <Dialog header="Reject Reason" visible={visibleReject} style={{ width: '50vw' }} onHide={() => {if (!visibleReject) return; setVisibleReject(false); }} footer={footerContent}>
          <div>
            <InputText placeholder="Reason" value={reason} onChange={e => setReason(e.target.value)} style={{width:'100%'}} />
            <div className="card flex flex-1 justify-content-left">
            <Dropdown value={selectedReject} onChange={(e) => setSelectedReject(e.value)} options={rejects} optionLabel="name" 
                placeholder="Select Reject Level" className="w-full" />
        </div>
          </div>
      </Dialog>
    </div>
  );
};
