// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/login.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup .content {
  min-height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
}
.signup .content .left .p-card {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  height: 100vh;
}
.signup .content .left .p-card .p-card-body .p-card-content {
  padding-top: 80px;
}
.signup .content .left .p-card .p-card-body .p-card-content .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 12px;
}
.signup .content .left .p-card .p-card-body .p-card-content .form-group .component {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.signup .content .left .p-card .p-card-body .p-card-content .form-group .component .p-inputtext {
  width: 100%;
}
.signup .content .left .p-card .p-card-body .p-card-content .form-group .component a {
  text-decoration: none;
}
.signup .content .left .p-card .p-card-body .p-card-content .form-group .component .p-password {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/signup.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;EACA,yDAAA;EACA,sBAAA;AADJ;AAGM;EACE,yDAAA;EACA,aAAA;AADR;AAGU;EACE,iBAAA;AADZ;AAEY;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;AAAd;AACc;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAChB;AAAgB;EACE,WAAA;AAElB;AAAgB;EACE,qBAAA;AAElB;AAAgB;EACE,cAAA;AAElB","sourcesContent":["@import \"../../style.scss\";\n.signup {\n  .content {\n    min-height: 100vh;\n    background-image: url(\"../../../public/images/frontcover.png\");\n    background-size: cover;\n    .left {\n      .p-card {\n        background-image: url(\"../../../public/images/login.png\");\n        height: 100vh;\n        .p-card-body {\n          .p-card-content {\n            padding-top: 80px;\n            .form-group {\n              display: flex;\n              flex-direction: column;\n              width: 100%;\n              align-items: center;\n              gap: 12px;\n              .component {\n                display: flex;\n                flex-direction: column;\n                width: 100%;\n                .p-inputtext {\n                  width: 100%;\n                }\n                a {\n                  text-decoration: none;\n                }\n                .p-password {\n                  display: block;\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
