import React, { useContext, useState, useEffect } from "react";
import { PrimeReactContext } from "primereact/api";
import "./userappbar.scss";
import { Menubar } from "primereact/menubar";
import Cookie from "js-cookie";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export const UserAppBar = () => {
  const uuid = Cookie.get("srxuuid");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const token = Cookie.get("srxtoken");
  const [data, setData] = useState({});
  const Navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );
  const { changeTheme } = useContext(PrimeReactContext);
  const changeMyTheme = () => {
    let currentTheme =
      darkMode === false ? "lara-light-teal" : "lara-dark-teal";
    let newTheme = darkMode === false ? "lara-dark-teal" : "lara-light-teal";
    changeTheme(currentTheme, newTheme, "app-theme", function () {
      setDarkMode(!darkMode);
      localStorage.setItem("darkMode", !darkMode);
    });
  };
  useEffect(() => {
    let currentTheme = darkMode === true ? "lara-light-teal" : "lara-dark-teal";
    let newTheme = darkMode === true ? "lara-dark-teal" : "lara-light-teal";
    changeTheme(currentTheme, newTheme, "app-theme", function () {
      // setDarkMode(!darkMode);
      // localStorage.setItem("darkMode", !darkMode);
    });
  }, [darkMode]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(
          url + "/user/getdashboarddata",
          {
            uuid,
          },
          {
            headers: {
              "x-api-key": apikey,
              "act-sessionid": token,
            },
          }
        )
        .then(function (resp) {
          if (resp.status === 200) {
            setData(resp.data.userdata);
          }
        })
        .catch(function (error) {
          Navigate("/");
        });
    };
    fetchData();
  }, []);
  let items
  if(data.kyc_level<5){
    items = [
      {
        label: "Home",
        icon: "pi pi-home",
        url: "/user/",
      },
      {
        label: "Wallets",
        icon: "pi pi-star",
        items: [
          {
            label: "Inr Wallet",
            icon: "pi pi-indian-rupee",
          },
          {
            label: "Crypto Wallet",
            icon: "pi pi-bitcoin",
          },
        ],
      },
  
      {
        label: "Trade",
        icon: "pi pi-chart-line",
        items: [
          {
            label: "Spot Trading",
            icon: "pi pi-bolt",
          },
          {
            label: "Margin Trading",
            icon: "pi pi-lock",
          },
          {
            label: "Future Trading",
            icon: "pi pi-lock",
          },
        ],
      },
      {
        label: "Market",
        icon: "pi pi-chart-bar",
      },
      {
        label: "P2P",
        icon: "pi pi-lock",
      },
      {
        label: "Settings",
        icon: "pi pi-cog",
        items: [
          {
            label: "Profile",
            icon: "pi pi-briefcase",
            url: "#",
          },
          {
            label: "Security",
            icon: "pi pi-shield",
            url: "#",
          },
        ],
      },
    ];
  }else{
    items = [
      {
        label: "Home",
        icon: "pi pi-home",
        url: "/user/",
      },
      {
        label: "Wallets",
        icon: "pi pi-star",
        items: [
          {
            label: "Inr Wallet",
            icon: "pi pi-indian-rupee",
            url: "/user/inrwallet",
          },
          {
            label: "Crypto Wallet",
            icon: "pi pi-bitcoin",
            url: "/user/cryptodeposit",
          },
        ],
      },
  
      {
        label: "Trade",
        icon: "pi pi-chart-line",
        items: [
          {
            label: "Spot Trading",
            icon: "pi pi-bolt",
            url: "/user/spottrading/soarxinr",
          },
          {
            label: "Margin Trading",
            icon: "pi pi-lock",
            url: "#",
          },
          {
            label: "Future Trading",
            icon: "pi pi-lock",
            url: "#",
          },
        ],
      },
      {
        label: "Market",
        icon: "pi pi-chart-bar",
        // url: "/user/",
      },
      {
        label: "P2P",
        icon: "pi pi-lock",
        // url: "/user/",
      },
      {
        label: "Settings",
        icon: "pi pi-cog",
        items: [
          {
            label: "Profile",
            icon: "pi pi-briefcase",
            url: "/user/profile",
          },
          {
            label: "Security",
            icon: "pi pi-shield",
            url: "/user/security",
          },
        ],
      },
    ];
  }
  
  const start = (
    <div className="flex align-items-center gap-1">
      <Link to="/user/">
        <img alt="logo" src="/images/logo.png" height="40" className="mr-2" />
      </Link>
      <span className="headlogo">SOARXBITS</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-5">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={() => changeMyTheme()}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={() => changeMyTheme()}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Log Out"
        icon="pi pi-power-off"
        severity="danger"
        size="small"
        onClick={() => logout()}
      />
    </div>
  );
  const logout = () => {
    localStorage.removeItem("soarxexactmail");
    Navigate("/");
  };
  return (
    <div>
      <div className="card userappbar">
        <Menubar model={items} start={start} end={end} className="surface-ground" />
      </div>
    </div>
  );
};
