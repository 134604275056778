// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cryptodeposit {
  padding: 80px 20px;
  background: rgb(76, 113, 164);
  background: linear-gradient(180deg, rgb(76, 113, 164) 0%, rgba(196, 240, 233, 0) 100%);
}
.cryptodeposit .walprofile {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cryptodeposit .walprofile .head {
  font-size: 16px;
}
.cryptodeposit .walprofile .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cryptodeposit .walprofile .data .action {
  display: flex;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/cryptodeposit/cryptodeposit.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,6BAAA;EACA,sFAAA;AAAF;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAII;EACE,eAAA;AAFN;AAII;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAFN;AAGM;EACE,aAAA;EACA,SAAA;AADR","sourcesContent":["@import \"../../../style.scss\";\n.cryptodeposit {\n  padding: 80px 20px;\n  background: rgb(76, 113, 164);\n  background: linear-gradient(\n    180deg,\n    rgba(76, 113, 164, 1) 0%,\n    rgba(196, 240, 233, 0) 100%\n  );\n  .walprofile{\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    .head{\n      font-size: 16px;\n    }\n    .data{\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      .action{\n        display: flex;\n        gap: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
