import React, { useEffect, useState } from "react";
import "./activepair.scss";
import axios from "axios";
import { Message } from "primereact/message";
export const ActivePair = (props) => {
  const { pair } = props;
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actemail = localStorage.getItem("soarxexactmail");
  const [ticker, setTicker] = useState({
    last_price:0,
    price_change_24hr:0,
    high:0,
    low:0,
    base_volume:0
  });
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getactivepair",
        {
          pair,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setTicker(resp.data.ticker);
        // setLoader(false);
      }
    };
    fetchData();
  }, []);
  const pairdetail = (
    <div className="activepair flex align-items-center gap-4">
      <div className="pair flex align-items-center gap-2">
        <img src={ticker.logo} alt="" width="34" />
        <div className="bq flex flex-column">
          <div className="base" style={{color:'var(--highlight-text-color)'}}>{ticker.base}</div>
          <div className="quote" style={{color: 'var(--text-color-secondary)'}}>{ticker.quote}</div>
        </div>
      </div>
      {/* <div className="cont">
        <div className="fs12" style={{color:'var(--highlight-text-color)'}}>Price</div>
        <div className="fs14" style={{color: 'var(--text-color-secondary)'}}>{(ticker.last_price).toFixed(3)}</div>
      </div> */}
      {/* <div className="cont">
        <div className="fs12" style={{color:'var(--highlight-text-color)'}}>24H Change</div>
        <div className="fs14" style={{color: 'var(--text-color-secondary)'}}>{(ticker.price_change_24hr).toFixed(3)}</div>
      </div> */}
      <div className="cont">
        <div className="fs12" style={{color:'var(--highlight-text-color)'}}>24H High</div>
        <div className="fs14" style={{color: 'var(--text-color-secondary)'}}>{(ticker.high).toFixed(3)}</div>
      </div>
      <div className="cont">
        <div className="fs12" style={{color:'var(--highlight-text-color)'}}>24H Low</div>
        <div className="fs14" style={{color: 'var(--text-color-secondary)'}}>{(ticker.low).toFixed(3)}</div>
      </div>
      <div className="cont">
        <div className="fs12" style={{color:'var(--highlight-text-color)'}}>24H Vol</div>
        <div className="fs14" style={{color: 'var(--text-color-secondary)'}}>{(ticker.base_volume).toFixed(3)}</div>
      </div>
    </div>
  );
  return (
    <div className="activepair">
      <Message style={{width:'100%', justifyContent:"left"}} content={pairdetail} />
    </div>
  );
};
