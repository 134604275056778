import React, { useContext, useState, useEffect } from "react";
import "./homeappbar.scss"
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import {Link} from "react-router-dom"
import { PrimeReactContext } from 'primereact/api';
// import sagaBlue from 'primereact/resources/themes/lara-dark-teal/theme.css'
export const HomeApp = () => {
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );
  const { changeTheme } = useContext(PrimeReactContext);
  const changeMyTheme = () => {
    let currentTheme = darkMode === false ? "lara-light-teal" : "lara-dark-teal"
    let newTheme = darkMode === false ?  "lara-dark-teal" : "lara-light-teal"
    changeTheme(currentTheme, newTheme, 'app-theme', function(){
      setDarkMode(!darkMode);
      localStorage.setItem("darkMode", !darkMode);
    })
  };
  useEffect(()=>{
    let currentTheme = darkMode === true ? "lara-light-teal" : "lara-dark-teal"
    let newTheme = darkMode === true ?  "lara-dark-teal" : "lara-light-teal"
    changeTheme(currentTheme, newTheme, 'app-theme', function(){
      // setDarkMode(!darkMode);
      // localStorage.setItem("darkMode", !darkMode);
    })
  },[darkMode])
    const start = (
        <div className="flex align-items-center gap-1">
          <Link to="/"><img
            alt="logo"
            src="/images/logo.png"
            height="40"
            className="mr-2"
            
          /></Link>
          <span className="headlogo">SOARXBITS</span>
        </div>
      );
      const end = (
        <div className="flex align-items-center gap-1">
          {darkMode ? (
            <span
              className="pi pi-moon"
              onClick={()=>changeMyTheme()}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <span
              className="pi pi-sun"
              onClick={()=>changeMyTheme()}
              style={{ cursor: "pointer" }}
            />
          )}
          <Button
            label="Log In"
            icon="pi pi-users"
            text
            size="small"
            onClick={() => (window.location.href = "/login")}
          />
          <Button
            label="Sign Up"
            icon="pi pi-user-plus"
            size="small"
            onClick={() => (window.location.href = "/signup")}
          />
        </div>
      );
  return (
      <div className="card homeappbar">
        <Menubar start={start} end={end}  className="surface-ground" />
    </div>
  )
}
