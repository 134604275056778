// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadkycdoc {
  padding: 80px 20px;
  min-height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
}
.uploadkycdoc img {
  width: 450px;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/uploadkycdoc/uploadkycdoc.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,iBAAA;EACA,yDAAA;EACA,4BAAA;AAAJ;AACI;EACI,YAAA;AACR","sourcesContent":["@import \"../../../style.scss\";\n.uploadkycdoc {\n    padding: 80px 20px;\n    min-height: 100vh;\n    background-image: url(\"../../../../public/images/frontcover.png\");\n    background-repeat: no-repeat;\n    img{\n        width: 450px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
