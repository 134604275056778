import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Message } from "primereact/message";
import img from "../../images/slider-img.png"
import whyus from "../../images/whyus.png"
import { TickerWidget } from "../../tradingview/TickerWidget";
import "./landing.scss";
export const LandingPage = () => {
  return (
    <div className="landing">
      <div className="hero">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-7 left">
            <div className="heading">
              <div className="title">SOARXBITS</div>
              <div className="bs text-primary">Buy & Sell</div>
              <div className="cr text-700">Crypto</div>
              <div className="bs text-primary">in Smoothen Way</div>
            </div>
            <Divider />
            <div className="quicksignup flex flex-column gap-1">
              <div className="font-bold">Quick Signup</div>
              <div className="controls flex gap-2">
                <InputText
                  placeholder="Email Address"
                  className="p-inputtext-sm"
                  style={{ width: "300px" }}
                />
                <Button label="Signup" size="small" />
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-5 right">
            <div className="cont">
             <img src={img} alt="" style={{width:'100%'}} className="vert-move" />
            </div>
          </div>
        </div>
      </div>
      <TickerWidget />
      <div className="whywe">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-7 flex flex-column gap-3">
            <div className="text-primary font-bold text-5xl">WHY WE?</div>
            <div className="text-300 font-bold text-2xl">INTRODUCTION:</div>
            <div>
              SOARXBITS is a cryptocurrency trading platform designed specially
              to assure smooth experience of trading.
            </div>
            <div>
              It is a reliable and secure way to buy, sell and trade crypto with
              low fee and unmatched liquidity. You can try yourself with
              SOARXBITS demo trading account, apply for free crypto, and play
              games to earn even more crypto.
            </div>
            <div>
              Best of all, we give you access to the widest variety of
              cryptocurrencies on the market.
            </div>
            <div className="text-primary font-bold text-5xl">Key Features</div>
            <Message
              severity="success"
              text="Security: The Cornerstone of a Successful Crypto Exchange."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Interactive User Interface."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Hassle-free execution."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Quick Buy and Sell Mechanism."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Advanced Trading Features."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Multi-Currency Support."
              style={{ width: "100%", justifyContent: "left" }}
            />
            <Message
              severity="success"
              text="Regulatory Compliance."
              style={{ width: "100%", justifyContent: "left" }}
            />
            
            <Message
              severity="success"
              text="24 X 7 Live support system."
              style={{ width: "100%", justifyContent: "left" }}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-5">
            <img src={whyus} alt="" style={{width:'100%'}} className="vert-move" />
          </div>
        </div>
      </div>
    </div>
  );
};
