// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/login.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  min-height: 100vh;
}
.login .content {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
}
.login .content .left .p-card {
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.login .content .left .p-card .p-card-body .p-card-content {
  padding-top: 80px;
}
.login .content .left .p-card .p-card-body .p-card-content .form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 12px;
}
.login .content .left .p-card .p-card-body .p-card-content .form-group .component {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.login .content .left .p-card .p-card-body .p-card-content .form-group .component .p-inputtext {
  width: 100%;
}
.login .content .left .p-card .p-card-body .p-card-content .form-group .component a {
  text-decoration: none;
}
.login .content .left .p-card .p-card-body .p-card-content .form-group .component .p-password {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/login.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;AACE;EACE,yDAAA;EACA,0BAAA;AACJ;AACM;EACE,aAAA;EACA,yDAAA;AACR;AACU;EACE,iBAAA;AACZ;AAAY;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;AAEd;AADc;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAGhB;AAFgB;EACE,WAAA;AAIlB;AAFgB;EACE,qBAAA;AAIlB;AAFgB;EACE,cAAA;AAIlB","sourcesContent":["@import \"../../style.scss\";\n.login {\n  min-height: 100vh;\n  .content {\n    background-image: url(\"../../../public/images/frontcover.png\");\n    background-size: 100% 100%;\n    .left {\n      .p-card {\n        height: 100vh;\n        background-image: url(\"../../../public/images/login.png\");\n        .p-card-body {\n          .p-card-content {\n            padding-top: 80px;\n            .form-group {\n              display: flex;\n              flex-direction: column;\n              width: 100%;\n              align-items: center;\n              gap: 12px;\n              .component {\n                display: flex;\n                flex-direction: column;\n                width: 100%;\n                .p-inputtext {\n                  width: 100%;\n                }\n                a {\n                  text-decoration: none;\n                }\n                .p-password {\n                  display: block;\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
