// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  padding: 80px 10px;
  background: rgb(76, 113, 164);
  background: linear-gradient(180deg, rgb(76, 113, 164) 0%, rgba(196, 240, 233, 0) 100%);
}
.profile .cont {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/profile/profile.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,6BAAA;EACA,sFAAA;AAAF;AAKE;EACE,aAAA;EACA,8BAAA;AAHJ","sourcesContent":["@import \"../../../style.scss\";\n.profile {\n  padding: 80px 10px;\n  background: rgb(76, 113, 164);\n  background: linear-gradient(\n    180deg,\n    rgba(76, 113, 164, 1) 0%,\n    rgba(196, 240, 233, 0) 100%\n  );\n  .cont{\n    display: flex;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
