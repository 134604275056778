// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/frontcover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing .hero {
  padding: 80px 20px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: 100% 100%;
}
.landing .hero .left {
  padding-top: 50px;
}
.landing .hero .left .title {
  font-size: 46px;
  font-weight: 700;
}
.landing .hero .left .bs {
  font-size: 66px;
  font-weight: 700;
}
.landing .hero .left .cr {
  font-size: 48px;
  font-weight: 700;
}
.landing .whywe {
  padding: 20px 20px;
}
.landing img.vert-move {
  animation: mover 1s infinite alternate;
}
.landing img.vert-move {
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/landing.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,6DAAA;EACA,0BAAA;AADJ;AAEI;EACE,iBAAA;AAAN;AACM;EACE,eAAA;EACA,gBAAA;AACR;AACM;EACE,eAAA;EACA,gBAAA;AACR;AACM;EACE,eAAA;EACA,gBAAA;AACR;AAME;EACE,kBAAA;AAJJ;AAME;EAEE,sCAAA;AAJJ;AAME;EAEE,sCAAA;AAJJ;AAcE;EACE;IACE,wBAAA;EAJJ;EAME;IACE,4BAAA;EAJJ;AACF","sourcesContent":["@import \"../../style.scss\";\n.landing {\n  .hero {\n    padding: 80px 20px;\n    background: url(\"../../../public/images/frontcover.png\") no-repeat;\n    background-size: 100% 100%;\n    .left {\n      padding-top: 50px;\n      .title {\n        font-size: 46px;\n        font-weight: 700;\n      }\n      .bs {\n        font-size: 66px;\n        font-weight: 700;\n      }\n      .cr {\n        font-size: 48px;\n        font-weight: 700;\n      }\n    }\n    .right {\n      \n    }\n  }\n  .whywe {\n    padding: 20px 20px;\n  }\n  img.vert-move {\n    -webkit-animation: mover 1s infinite alternate;\n    animation: mover 1s infinite alternate;\n  }\n  img.vert-move {\n    -webkit-animation: mover 1s infinite alternate;\n    animation: mover 1s infinite alternate;\n  }\n  @-webkit-keyframes mover {\n    0% {\n      transform: translateY(0);\n    }\n    100% {\n      transform: translateY(-10px);\n    }\n  }\n  @keyframes mover {\n    0% {\n      transform: translateY(0);\n    }\n    100% {\n      transform: translateY(-10px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
