import React, { useEffect, useRef, useState } from "react";
import "./admininrdeposit.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
export const AdminInrDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [deposits, setDeposits] = useState({});
  const [visible, setVisible] = useState(false);
  const [rejectId, setRejectId] = useState("")
  const [reason, setReason] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getinrdeposits", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setDeposits(resp.data.inrdeposit);
      }
    };
    fetchData();
  }, []);
  const confirmReject =async () =>{
    const resp = await axios.post(
      url + "/admin/rejectinrdeposit",
      {
        rejectId,
        reason
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      setVisible(false)
      setRejectId("")
      setReason("")
    }
  }
  const footerContent = (
    <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
        <Button label="Confirm" icon="pi pi-check" onClick={() => confirmReject()} autoFocus />
    </div>
);
  const acceptDeposit = async (tranid) => {
    const resp = await axios.post(
      url + "/admin/acceptinrdeposit",
      {
        tranid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
  };
  const tranAccept = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-check-square"
          severity="info"
          size="small"
          onClick={() => acceptDeposit(rowData.tranid)}
        />
      </>
    );
  };
  const rejectDeposit = (tranid)=>{
    // alert(tranid)
    setRejectId(tranid)
    setVisible(true)
  }
  const tranReject = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          severity="warning"
          size="small"
          onClick={() => rejectDeposit(rowData.tranid)}
        />
      </>
    );
  };
  const bank = (rowData) => {
    return (
      <>
        {rowData.data[0]?rowData.data[0].accno:"Not"}
      </>
    );
  };
  return (
    <div className="admininrdeposit">
      <DataTable value={deposits} size="small" style={{ width: "100%" }}>
        <Column field="tran_date" header="Date"></Column>
        <Column field="user_name" header="User Name"></Column>
        <Column field={bank} header="Bank"></Column>
        <Column field="tranid" header="Tran Id"></Column>
        <Column field="amount" header="Amount"></Column>
        <Column header="Accept" body={tranAccept}></Column>
        <Column header="Reject" body={tranReject}></Column>
      </DataTable>
      <Dialog header="Reject Reason" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }} footer={footerContent}>
          <div>
            <InputText placeholder="Reason" value={reason} onChange={e => setReason(e.target.value)} style={{width:'100%'}} />
          </div>
      </Dialog>
    </div>
  );
};
