// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader .animation {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: fixed;
  top: 45%;
  left: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/usercomponent/loader.scss"],"names":[],"mappings":"AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;AADR","sourcesContent":["@import \"../../style.scss\";\n.loader {\n    .animation{\n        width: 80px;\n        height: 80px;\n        border-radius: 50%;\n        position: fixed;\n        top: 45%;\n        left: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
