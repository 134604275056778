import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./style.scss";
import { DarkModeContext } from "./context/darkModeContext";
import { PrimeReactContext } from "primereact/api";
import React, { useContext, useEffect, useState } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { LandingPage } from "./pages/home/LandingPage";
import { HomeApp } from "./components/homecomponent/HomeApp";
import { HomeFoot } from "./components/homecomponent/HomeFoot";
import { Login } from "./pages/home/Login";
import { SignUp } from "./pages/home/SignUp";
import { UserAppBar } from "./components/usercomponent/UserAppBar";
import { UserFooter } from "./components/usercomponent/UserFooter";
import { DashBoard } from "./pages/user/dashboard/DashBoard";
// import laraLight from "primereact/resources/themes/lara-light-teal/theme.css";
import PrimeReact from 'primereact/api';
import { MobileVerification } from "./pages/user/mobileverification/MobileVerification";
import { UploadKyc } from "./pages/user/uploadkyc/UploadKyc";
import { CryptoDeposit } from "./pages/user/cryptodeposit/CryptoDeposit";
import { SpotWallet } from "./pages/user/spotwallet/SpotWallet";
import { InrWallet } from "./pages/user/inrwallet/InrWallet";
import { SpotTrading } from "./pages/user/spottrading/SpotTrading";
import { Profile } from "./pages/user/profile/Profile";
import { AdminLogin } from "./pages/home/AdminLogin";
import { AdminAppBar } from "./components/admincomponent/AdminAppBar";
import { AdminFooter } from "./components/admincomponent/AdminFooter";
import { AdminDashBoard } from "./pages/admin/admindashboard/AdminDashBoard";
import { PendingKyc } from "./pages/admin/pendingkyc/PendingKyc";
import { AdmWalletBalance } from "./pages/admin/userwalletbalance/AdmWalletBalance";
import { ForgetPassword } from "./pages/home/ForgetPassword";
import { AdminInrDeposit } from "./pages/admin/admininrdeposit/AdminInrDeposit";
import { Security } from "./pages/user/security/Security";
import { UploadKycDoc } from "./pages/user/uploadkycdoc/UploadKycDoc";
import { AdminInrWithdraw } from "./pages/admin/admininrwithdraw/AdminInrWithdraw";
function App() {
  const [theme, setTheme] = useState('dark');
  // const { darkMode } = useContext(DarkModeContext);
  // useEffect(() => {
  //   let themeLink = document.getElementById("app-theme");
  //   const darkTheme = "lara-dark-teal";
  //   const lightTheme = "lara-light-teal";
  //   if (themeLink) {
  //     themeLink.href = `${process.env.REACT_APP_URL}/themes/${
  //       darkMode.value ? darkTheme : lightTheme
  //     }/theme.css`;
  //   }
  // }, [darkMode]);
  // console.log(darkMode.value)
  // changeTheme(currentTheme: string, newTheme: string, linkElementId: string, callback: Function)
  const HomeLayout = () => {
    return (
      <div>
        <HomeApp />
        <Outlet />
        <HomeFoot />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div>
        <UserAppBar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div>
        <AdminAppBar />
        <Outlet />
        <AdminFooter />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <SignUp /> },
        { path: "/forgetpassword", element: <ForgetPassword /> },
        { path: "/adminlogin", element: <AdminLogin /> },
      ],
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <DashBoard /> },
        { path: "/user/mobileverification", element: <MobileVerification /> },
        { path: "/user/uploadkyc", element: <UploadKyc /> },
        { path: "/user/uploadkycdoc", element: <UploadKycDoc /> },
        { path: "/user/cryptodeposit", element: <CryptoDeposit /> },
        { path: "/user/spotwallet/:currency", element: <SpotWallet /> },
        { path: "/user/inrwallet", element: <InrWallet /> },
        { path: "/user/spottrading/:pair", element: <SpotTrading /> },
        { path: "/user/profile", element: <Profile /> },
        { path: "/user/security", element: <Security /> },
        // { path: "/user/requesttopupinr", element: <RequestTopUpInr /> },
        // { path: "/user/incomedetails", element: <IncomeDetails /> },
        // { path: "/user/settings", element: <Settings /> },
        // { path: "/user/p2ptransfer", element: <P2PTransfer /> },
        // { path: "/user/manageweekly", element: <ManageWeekly /> },
        // { path: "/user/managemonthly", element: <ManageMonthly /> },
        // { path: "/user/myaccount", element: <MyAccount /> },
        // { path: "/user/userprofile", element: <UserProfile /> },
        // { path: "/user/cryptodeposit", element: <CryptoDeposit /> },
        // { path: "/user/livebalancereq", element: <LiveBalanceReq /> },
        // { path: "/user/downline", element: <Downline /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/", element: <AdminDashBoard /> },
        { path: "/admin/pendingkyc", element: <PendingKyc /> },
        { path: "/admin/walletbalance", element: <AdmWalletBalance /> },
        { path: "/admin/inrdeposit", element: <AdminInrDeposit /> },
        { path: "/admin/inrwithdraw", element: <AdminInrWithdraw /> },
        // { path: "/admin/walletrequest", element: <WalletRequest /> },
        // { path: "/admin/credituser", element: <CreditUser /> },
        // { path: "/admin/allusers", element: <AllUsers /> },
        // { path: "/admin/usersprofile", element: <UsersProfile /> },
        // { path: "/admin/liveaccountreq", element: <LiveAccountReq /> },
        // { path: "/admin/editprofile/:email", element: <EditProfile /> },
    ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
